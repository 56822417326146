import React from 'react'

function Home({ fill = '#A288DF', opacity = 1 }) {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M7.92004 3H4.33718C3.80914 3 3.54511 3 3.34342 3.10899C3.16601 3.20487 3.02177 3.35785 2.93138 3.54601C2.82861 3.75992 2.82861 4.03995 2.82861 4.6V8.4C2.82861 8.96005 2.82861 9.24008 2.93138 9.45399C3.02177 9.64215 3.16601 9.79513 3.34342 9.89101C3.54511 10 3.80914 10 4.33718 10H7.92004C8.44809 10 8.71212 10 8.9138 9.89101C9.09121 9.79513 9.23545 9.64215 9.32585 9.45399C9.42861 9.24008 9.42861 8.96005 9.42861 8.4V4.6C9.42861 4.03995 9.42861 3.75992 9.32585 3.54601C9.23545 3.35785 9.09121 3.20487 8.9138 3.10899C8.71212 3 8.44809 3 7.92004 3Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2915 3H14.7086C14.1806 3 13.9165 3 13.7148 3.10899C13.5374 3.20487 13.3932 3.35785 13.3028 3.54601C13.2 3.75992 13.2 4.03995 13.2 4.6V8.4C13.2 8.96005 13.2 9.24008 13.3028 9.45399C13.3932 9.64215 13.5374 9.79513 13.7148 9.89101C13.9165 10 14.1806 10 14.7086 10H18.2915C18.8195 10 19.0835 10 19.2852 9.89101C19.4626 9.79513 19.6069 9.64215 19.6973 9.45399C19.8 9.24008 19.8 8.96005 19.8 8.4V4.6C19.8 4.03995 19.8 3.75992 19.6973 3.54601C19.6069 3.35785 19.4626 3.20487 19.2852 3.10899C19.0835 3 18.8195 3 18.2915 3Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2915 14H14.7086C14.1806 14 13.9165 14 13.7148 14.109C13.5374 14.2049 13.3932 14.3578 13.3028 14.546C13.2 14.7599 13.2 15.0399 13.2 15.6V19.4C13.2 19.9601 13.2 20.2401 13.3028 20.454C13.3932 20.6422 13.5374 20.7951 13.7148 20.891C13.9165 21 14.1806 21 14.7086 21H18.2915C18.8195 21 19.0835 21 19.2852 20.891C19.4626 20.7951 19.6069 20.6422 19.6973 20.454C19.8 20.2401 19.8 19.9601 19.8 19.4V15.6C19.8 15.0399 19.8 14.7599 19.6973 14.546C19.6069 14.3578 19.4626 14.2049 19.2852 14.109C19.0835 14 18.8195 14 18.2915 14Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.92004 14H4.33718C3.80914 14 3.54511 14 3.34342 14.109C3.16601 14.2049 3.02177 14.3578 2.93138 14.546C2.82861 14.7599 2.82861 15.0399 2.82861 15.6V19.4C2.82861 19.9601 2.82861 20.2401 2.93138 20.454C3.02177 20.6422 3.16601 20.7951 3.34342 20.891C3.54511 21 3.80914 21 4.33718 21H7.92004C8.44809 21 8.71212 21 8.9138 20.891C9.09121 20.7951 9.23545 20.6422 9.32585 20.454C9.42861 20.2401 9.42861 19.9601 9.42861 19.4V15.6C9.42861 15.0399 9.42861 14.7599 9.32585 14.546C9.23545 14.3578 9.09121 14.2049 8.9138 14.109C8.71212 14 8.44809 14 7.92004 14Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}


export default Home
