import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext  from '../context/SubgraphsContext'


const useEnvironmentImplementation = id => {
  const subgraphs = useContext(SubgraphsContext)

  return useQuery(['useEnvironmentsImplementations', id, subgraphs], () =>
    fetchAllTasks(id, subgraphs)
  )
}

const fetchEnvironment = async (chainId, id, subgraphs) => {
  let environment = await request(
    subgraphs[chainId],
    gql`
    query Environment {
      environment(id: ${'"' + id.toLowerCase() + '"'}) {
        tasks {
          id
          name
          implementation {
            id
            name
          }
        }
        smartVaults {
          implementation {
            id
            name
          }
          priceOracle {
            implementation {
              id
              name
            }
          }
        }
      }
    }
    `
  )

  return environment?.environment
}

const fetchAllTasks = async (id, subgraphs) => {
  let allTasks = []
  await Promise.all(Object.keys(subgraphs).map(async (chainId) => {
    const configs = await fetchEnvironment(chainId, id, subgraphs)
    if (configs) {
      configs?.tasks?.forEach(obj => {
        const ob = {
          chainId: chainId,
          task: obj?.name,
          implementation: obj?.implementation?.name,
          address: obj?.implementation?.id,
        }
        allTasks.push(ob)
      })
      const smartVault = {
        chainId: chainId,
        task: 'smart-vault',
        implementation: configs?.smartVaults[0]?.implementation?.name,
        address: configs?.smartVaults[0]?.implementation?.id,
      }

      const priceOracle = {
        chainId: chainId,
        task: 'price-oracle',
        implementation: configs?.smartVaults[0]?.priceOracle.implementation?.name,
        address: configs?.smartVaults[0]?.priceOracle.implementation?.id,
      }
      allTasks.push(smartVault)
      allTasks.push(priceOracle)
    }
  }))

  const sortedAllTask = allTasks.sort((a, b) => {
    const taskA = a.task.toLowerCase()
    const taskB = b.task.toLowerCase()

    if (taskA < taskB) {
      return -1
    }
    if (taskA > taskB) {
      return 1
    }
    return 0
  })

  return sortedAllTask
}

export default useEnvironmentImplementation
