export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function parseActionName(action) {
  return action
    .split(/\s|-/)
    .map(word => capitalizeFirstLetter(word))
    .join(' ')
}

export const renderThumbVertical = ({ style, ...props }) => {
  const thumbStyle = {
    backgroundColor: '#5E5F65',
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}

export function formattedNumber(value) {
  return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
