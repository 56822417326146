import React from 'react'
import styled from 'styled-components'
import background from '../assets/no-chart-bg.png'
import octo from '../assets/octo-chart.png'
import { Heading1, SmallText } from '../styles/texts'
import MovingComponent from './MovingComponent'

const Empty = ({ width, text = '', title = 'Waiting for data' }) => {
  const medium = 700
  return (
    <>
      <NoChart background={background}>
        {width < medium && (
          <MovingComponent>
            <img src={octo} alt="octopus" />
          </MovingComponent>
        )}
        <div>
          <Heading1>{title}</Heading1>
          <GreyText>{text}</GreyText>
        </div>
        {width > medium && (
          <MovingComponent>
            <img src={octo} alt="octopus" />
          </MovingComponent>
        )}
      </NoChart>
    </>
  )
}

const NoChart = styled.div`
  padding: 55px 0 55px 55px;
  img {
    padding-left: 50px;
  }
  @media only screen and (max-width: 1100px) {
    padding: 15px;
    h1,
    p {
      text-align: center;
    }
    img {
      width: 80%;
    }
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;

    img {
      padding-left: 0px;
      margin: 0 10%;
    }
  }
  background: url(${props => props.background}) no-repeat center;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    max-width: 374px;
  }
`

const GreyText = styled(SmallText)`
  color: #a7acc1;
  max-width: 330px;
`

export default Empty
