import React, { useRef, useState, useContext } from 'react'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import Overview from '../assets/menuIcons/Overview.js'
import Activity from '../assets/menuIcons/Activity.js'
import Settings from '../assets/menuIcons/Settings.js'
import smartVault from '../assets/smart-vault.svg'
import hamburguer from '../assets/hamburguer.svg'
import close from '../assets/close.svg'
import { EnvironmentsDictionary } from '../constants/environmentsDictionary'
import EnvironmentContext  from '../context/EnvironmentContext'

const MobileMenu = () => {
  const contentEl = useRef()
  const url = window.location.href
  const [active, setActive] = useState(false)


  const params = useParams()
  const id = params.id
  const environment = useContext(EnvironmentContext)

  const handleToggle = () => {
    setActive(!active)
  }

  let page = url.split(id)[1]
  if (page?.startsWith('/')) {
    page = page.slice(1).split('/')[0]
  }

  return (
    <Card>
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active ? 'active' : ''}`}
          onClick={() => handleToggle()}
        >
          <div className="header">
            <img
              alt="smart vault logo"
              className={`logo ${active ? 'active' : ''}`}
              src={EnvironmentsDictionary[id]?.logo || smartVault}
            />
            <h5 className={`title ${active ? 'active' : ''}`}>
              {EnvironmentsDictionary[id]?.name || environment?.data?.namespace?.replace(/-/g, ' ')}
            </h5>
          </div>
          <img alt="toggle" src={active ? close : hamburguer} />
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active ? 'show' : ''}`}
        style={
          active
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <div className="rc-accordion-body">
          <h2>{EnvironmentsDictionary[id]?.name || environment?.data?.namespace?.replace(/-/g, ' ')}</h2>

          <MenuBox>
            <Item to={'/' + id + '/overview'}>
              <Overview fill={page !== 'overview' ? '#A4A4A4' : '#8865D4'} />
              <Label color={page === 'overview' ? '#8865D4' : '#A4A4A4'}>
                Overview
              </Label>
            </Item>
            <Item to={'/' + id + '/activity'}>
              <Activity fill={page !== 'activity' ? '#A4A4A4' : '#8865D4'} />
              <Label color={page === 'activity' ? '#8865D4' : '#A4A4A4'}>
                Activity
              </Label>
            </Item>

            <Item to={'/' + id + '/settings'}>
              <Settings fill={page !== 'settings' ? '#A4A4A4' : '#8865D4'} />
              <Label color={page === 'settings' ? '#8865D4' : '#A4A4A4'}>
                Settings
              </Label>
            </Item>
          </MenuBox>
        </div>
      </div>
    </Card>
  )
}

const Item = styled(Link)`
  height: 37px;
  padding: 10px 15px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-top: 1px solid #2c3041;
  &:hover {
    background: #313545;
  }
`

const Label = styled.div`
  color: ${({ color }) => (color ? color : 'inherit')};
  font-family: 'DMSans'
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 110%;
  letter-spacing: -0.02em;
  width: 70px;
  padding-left: 17px;
  text-align: left;
  transition: all 0.3s ease-in-out;
`

const MenuBox = styled.div`
  margin: 40px -15px -15px -15px;
`

const Card = styled.div`
  background: #232632;
  width: 90%;
  margin: 50px auto;
  height: fit-content;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }

  img.logo {
    height: 35px;
    border-radius: 100px;
    transition: all 0.6s ease-out;
  }
  img.logo.active {
    height: 60px;
  }

  .header {
    display: flex;
    align-items: center;
    transition: all 0.6s ease-out;
  }

  h5 {
    font-family: 'DMSansBold';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: white;
    padding: 15px;
    transition: all 0.6s ease-out;
  }
  h5.active {
    opacity: 0;
  }

  .rc-accordion-toggle {
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    justify-content: space-between;
    transition: 0.3s;
  }

  .rc-accordion-toggle.active .rc-accordion-icon {
    transform: rotate(180deg);
    color: ${props => props.theme.textWhite};
  }

  .rc-accordion-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;

    h2 {
      color: ${props => props.theme.textWhite};
      font-family: 'DMSansBold';
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
    }
    p {
      color: #a5a1b7;
      font-family: 'DMSans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
    button,
    a.button {
      padding: 6px 39px 6px 20px;
      border: 0px;
      border-radius: 500px;
      background: #313545;
      color: white;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Visby';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      display: flex;
      align-items: center;
      img {
        padding-left: 7px;
      }
    }
  }
  .rc-collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  .rc-collapse.show {
    height: auto;
  }
`

export default MobileMenu
