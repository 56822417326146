import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Container } from '../styles/texts'
import TasksChart from '../sections/TasksChart'
import Breadcrumbs from '../structure/Breadcrumbs'
import ActiveNetworks from '../sections/ActiveNetworks'
import EmptyState from '../components/EmptyState'
import { Skeleton } from '../styles/general'
import { Heading1, Heading3 } from '../styles/texts'
import useExecutions from '../hooks/useExecutions'
import useReports from '../hooks/useReports'

const Overview = ({ width }) => {
  const params = useParams()
  const [datesData, setDatesData] = useState({
    endDate: null,
    startDate: null,
  })
  const executions = useExecutions(params.id, 1, 1)
  useEffect(() => {
    var date2 = new Date()
    var date1 = new Date(date2)
    date1.setMonth(date1.getMonth() - 1)
    setDatesData({startDate: date1.toISOString(), endDate: date2.toISOString()})
  }, [params.id])

  return (
    <OverviewSection>
      <Container>
        <Breadcrumbs />
        <Heading1 style={{ marginTop: '80px' }}>
          {executions.isLoading ? (
            <Skeleton height="24px" width="480px" />
          ) : (
                <>
                  {Object.keys(executions.data).length > 0 ? (
                    <>
                      {Object.values(executions.data).reduce(
                        function(acc, curr) {
                          return (acc + (curr.executionSucceeded || 0))
                        }, 0)
                      }
                      {' '}tasks completed
                      <br /> over the last 24 hours
                    </>
                  ) : (
                    'No new task over the last 24 hours'
                  )}
                </>
              )
          }
        </Heading1>
        { (!datesData.startDate || !datesData.endDate) ? (
          <Skeleton height="570px" width="100%" />
        ) : (
          <>
            <Heading3 style={{ marginTop: '32px', textAlign: 'left' }}>
              TOTAL TASKS
            </Heading3>
            <Chart datesData={datesData} id={params.id} width={width} />
          </>
        )}
        <br />
        <br />
        <br />
        <br />
        <Heading3>MOST ACTIVE NETWORKS OVER THE LAST THREE MONTHS</Heading3>
        <ActiveNetworks />
        <br />
        <br />
      </Container>
    </OverviewSection>
  )
}


const Chart = ({ datesData, id, width }) => {

  const reports = useReports(id, 1, datesData.startDate, datesData.endDate)

  return (
    <>{ reports.data ? (
      <TasksChart data={reports.data} />
    ) : (
      <EmptyState text="Our octo is out exploring data for you. Will return with a chart soon!" width={width} />
    )} </>
  )
}

const OverviewSection = styled.section`
  height: auto;
  padding: 20px 0;
  color: ${props => props.theme.textWhite};
  width: 100%;
  margin: auto;
`

export default Overview
