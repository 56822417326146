import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const useExecutions = (id) => {
  return useQuery(
    {
      queryKey: ['executions', id],
      queryFn: () => fetchExecutions(id),
      // keepPreviousData: true,
      staleTime: 1000,
    }
  )
}

const fetchExecutions = async (id) => {
  if (!id) return []
  const url = `https://api.mimic.fi/public/environments/${id}/summary`

  var endDate = new Date().toISOString()
  var date = new Date(endDate)

  date.setMonth(date.getMonth() - 3)


  var startDate = date.toISOString()

  try {
    const params = {
      startDate: startDate,
      endDate: endDate,
    }

    const { data } = await axios.get(url, {
      params: { ...params }
    })

    return data
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return false
    }
    throw error
  }
}

export default useExecutions
