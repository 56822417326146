import React from 'react'
import styled from 'styled-components'
import Network from './Network'

const Networks = ({networks}) => {
  return (
    <Container>
      {networks && Object.values(networks).map((network, index) => (
        <Network network={network} small={true} key={index} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 34px;
  margin-right: 17px;
  img {
    margin: 0 -10px 0 0 !important;
    height: 25px !important;
  }
`

export default Networks
