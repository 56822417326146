/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

const SubgraphsContext = createContext()

export const SubgraphsProvider = ({ children }) => {
  const [data, setData] = useState(null)
  const fetchData = async () => {
    try {
      const response = await axios.get(
        'https://api.mimic.fi/public/subgraphs',
      )
      setData(response.data)
    } catch (error) {
      console.error('Error fetching the subgraphs url')
    }
  }


  useEffect(() => {
    fetchData()
  }, [])

  return (
    <SubgraphsContext.Provider value={data}>
      {children}
    </SubgraphsContext.Provider>
  )
}

export default SubgraphsContext
