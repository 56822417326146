import React from 'react'
import styled from 'styled-components'
import useFailedReason from '../hooks/useFailedReason'

const ActivityDetail = ({ item }) => {
  const reason = useFailedReason(item.transaction.hash, item.smartVault.environment.network, item.index)
  return (
    <Container>
      {!reason.isLoading && reason.data && reason.data.status?.reason}
    </Container>
  )
}

const Container = styled.div`
  color: #df0000;
`

export default ActivityDetail
