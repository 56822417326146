import React from 'react'

function Reports({ fill = '#A288DF' }) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3789_14728)">
        <path d="M14.9995 16V21M20.9995 21L17.0481 17.7072C16.3193 17.0998 15.9549 16.7962 15.5482 16.6801C15.1896 16.5778 14.8095 16.5778 14.4508 16.6801C14.0441 16.7962 13.6797 17.0998 12.9509 17.7072L8.99951 21M10.9995 11V12M14.9995 9V12M18.9995 7V12M24.9995 3H4.99951M5.99951 3H23.9995V11.2C23.9995 12.8802 23.9995 13.7202 23.6725 14.362C23.3849 14.9265 22.926 15.3854 22.3615 15.673C21.7197 16 20.8797 16 19.1995 16H10.7995C9.11935 16 8.27928 16 7.63754 15.673C7.07305 15.3854 6.61411 14.9265 6.32649 14.362C5.99951 13.7202 5.99951 12.8802 5.99951 11.2V3Z" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <filter id="filter0_d_3789_14728" x="-1" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3789_14728"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3789_14728" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}

export default Reports
