import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const useEnvironments = () => {
  return useQuery(
    {
      queryKey: ['environments'],
      queryFn: () => fetchEnvs()
    }
  )
}

const fetchEnvs = async () => {
  const url = 'https://api.mimic.fi/public/environments?active=true'

  try {
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log('No reason')
      return false
    }
    throw error
  }
}

export default useEnvironments
