import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CHAIN_INFO } from '../constants/chainInfo'

const Network = ({ network, width, networkName = '', small = false, noLogo=false }) => {
  const [totalWidth, setTotalWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => setTotalWidth(window.innerWidth))
  }, [])

  const selectedChain =
    Object.values(CHAIN_INFO).find(
      chain => chain.name.toLowerCase() === networkName.toLowerCase()
    ) || CHAIN_INFO[network]


  const medium = 700

  if (selectedChain) {
    if (noLogo) {
      return(<>{selectedChain?.name}</>)
    }
    return (
      <NetworkContainer>
        {!noLogo && <ChainLogo src={selectedChain?.logoUrl} small={small.toString()} />}
        {small !== true &&
          <span>{(!totalWidth || totalWidth >= medium) ? selectedChain?.name : selectedChain?.name.substring(0, 4)}</span>
        }
      </NetworkContainer>
    )
  }

  return <>{network ? network : ''}</>
}

const NetworkContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ChainLogo = styled.img`
  width: 23px;
  object-fit: scale-down;
  margin-right: ${props => props.small === 'true' ? '0px' : '10px'};
`
export default Network
