import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import AccordionItem from '../sections/AccordionItem'
import ParamTable from '../sections/ParamTable'
import GeneralImplementations from '../sections/GeneralImplementations'
import { Skeleton } from '../styles/general'
import { Heading1, Heading3 } from '../styles/texts'
import { ConfigContainer } from '../styles/general'
import useSmartVaultConfig from '../hooks/useSmartVaultConfig'
import { parseActionName } from '../utils/general-utils'

const GeneralParams = ({ width }) => {
  const [active, setActive] = useState('Address')
  const params = useParams()
  const data = useSmartVaultConfig(params?.id)
  const handleToggle = index => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }
  return (
    <ConfigContainer style={{ paddingTop: '0' }}>
      <Heading1>General Params</Heading1>
      {data.isLoading ? (
        <Skeleton height="24px" width="100%"/>
      ) :(
        <>
        {
          data?.data && Object.keys(data.data).map(
            key =>
              data.data[key] &&
              data.data[key].length > 0 && (
                <AccordionItem
                  key={key}
                  active={active}
                  handleToggle={handleToggle}
                  id={key}
                  header={<Heading3>{parseActionName(key)}</Heading3>}
                  content={<ParamTable data={data.data[key]} />}
                />
              )
          )
        }
        <AccordionItem
          active={active}
          handleToggle={handleToggle}
          id={'implementation'}
          header={<Heading3>Implementations</Heading3>}
          content={<GeneralImplementations/>}
        />
      </>)
    }
    </ConfigContainer>
  )
}

export default GeneralParams
