import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ConfigContainer } from '../styles/general'
import useEnvironmentImplementation from '../hooks/useEnvironmentImplementation'
import styled from 'styled-components'
import TableRow from '../structure/Table/TableRow'
import TableCell from '../structure/Table/TableCell'
import TableHeader from '../structure/Table/TableHeader'
import { CHAIN_INFO } from '../constants/chainInfo'
import open from '../assets/open.svg'
import { parseActionName } from '../utils/general-utils'
import { getEtherscanLink } from '../utils/web3-utils'
import SubgraphsContext  from '../context/SubgraphsContext'

const GeneralImplementations = ({ width }) => {
  const params = useParams()

  const subs = useContext(SubgraphsContext)
  const data = useEnvironmentImplementation(params?.id).data

  const tasks = new Set()
  const networks = new Set()
  data?.forEach(item => {
    tasks.add(item.task)
    networks.add(item.chainId)
  })

  const pivotTable = {}
  tasks?.forEach(task => {
    pivotTable[task] = {}
    networks.forEach(network => {
      pivotTable[task][network] = ''
    })
  })

  data?.forEach(({ chainId, task, implementation, address }) => {
    pivotTable[task][chainId] = {
      implementation: implementation,
      address: address,
    }
  })

  return (
    <ConfigContainer style={{ paddingTop: '0' }}>
      <PivotTable data={pivotTable} />
    </ConfigContainer>
  )
}

const PivotTable = ({ data }) => {
  const tasks = Object.keys(data)
  const networks = tasks.length > 0 ? Object.keys(data[tasks[0]]) : []

  return (
    <TableContainer>
      <StickyTable>
        <table>
          <thead>
            <TableRow>
              <TableHeader title="Task / Network" align="left" />
              {networks.map(network => (
                <TableHeader
                  title={CHAIN_INFO[network].name}
                  align="left"
                  key={network}
                />
              ))}
            </TableRow>
          </thead>
          <tbody>
            {tasks.map(task => (
              <TableRow key={task}>
                <TitleCell>{parseActionName(task)}</TitleCell>
                {networks.map(network => (
                  <Cell key={network}>
                    {data[task][network].implementation ? (
                      <a
                        href={getEtherscanLink(
                          network,
                          data[task][network].address,
                          'address'
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data[task][network].implementation}
                        <img src={open} alt="open" />
                      </a>
                    ) : (
                      '-'
                    )}
                  </Cell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </table>
      </StickyTable>
    </TableContainer>
  )
}

const Cell = styled(TableCell)`
  white-space: nowrap;
  text-overflow: ellipsis;
  img {
    height: 15px;
    width: 15px;
    padding: 0px;
    margin: 0 5px;
  }
  a {
    display: flex;
    align-items: center;
  }
`

const TitleCell = styled(TableCell)`
  color: #33C2B0;
  font-weight: bold;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const TableContainer = styled.div`
  overflow-x: auto;
  background: #2D2C44;
  padding: 11px 0 0 0;
  border-radius: 8px;
  th {
    padding-top: 0;
  }
  tr {
    padding: 0 21px;
    box-sizing: border-box;
    th:first-child, td:first-child {
      padding-left: 21px;
    }
    td:first-child {
      border-right: solid 1px #ffffff2e;
    }
    td{
      border-bottom: solid 1px #ffffff2e;
    }
  }
  thead {
    th{
      border-bottom: solid 1px #ffffff2e;
    }
  }
`

const StickyTable = styled.div`
  overflow-x: auto;
  max-width: 100%;
  position: relative;

  th:first-child {
    min-width: 200px;
    max-width: 200px;
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #2D2C44;
  }

  tbody {
    tr {
      td:first-child {
        position: sticky;
        left: 0;
        background-color: #2D2C44;
      }
    }
  }
`

export default GeneralImplementations
