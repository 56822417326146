import React from 'react'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import Network from '../components/Network'
import Table from '../structure/Table/Table'
import TableRow from '../structure/Table/TableRow'
import TableHeader from '../structure/Table/TableHeader'
import TableCell from '../structure/Table/TableCell'
import useActiveNetworks from '../hooks/useActiveNetworks'

const ActiveNetworks = () => {
  const params = useParams()
  const { data } = useActiveNetworks(params.id)

  return (
    <>
      <Table
        header={
          <TableRow>
            <TableHeader title="Network" align="left" />
            <TableHeader title="Total runs" align="left" />
            <TableHeader title="Last run" align="left" />
            <TableHeader title=" " align="left" />
          </TableRow>
        }
      >
        {data && Object.keys(data).map((network, index) => (
          <TableRow key={index}>
            <TableCell>
              <Network network={network} />
            </TableCell>
            <TableCell>{data[network].executions}</TableCell>
            <TableCell>
              {moment(data[network].lastExecution).fromNow()}
            </TableCell>
            <TableCell>
              <Link to={'/' + params.id + '/activity?chainId=' + network}>
                <View>View activity</View>
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  )
}

export default ActiveNetworks

const View = styled.div`
  color: #6f5ce6;
  font-family: "DMSansBold";
  font-weight: 700;
`
