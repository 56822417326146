import React from 'react'
import styled from 'styled-components'
import fail from '../assets/status-fail.svg'
import attempt from '../assets/status-attempt.svg'
import success from '../assets/status-success.svg'

const statusStyles = {
  fail: {
    color: '#DE0000',
  },
  reverted: {
    color: '#DE0000',

  },
  success: {
    color: '#33C2B0',
  },
  simulatedOk: {
    color: '#FFF',
  },
  notSimulated: {
    color: '#FFF',
  },
  simulatedFail: {
    color: '#FFF',
  },
}

const StatusContainer = styled.span`
  display: flex;
  align-items: center;
  color: ${({ status }) => statusStyles[status]?.color};
  font-family: 'DMSansBold';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.75px;
`

const StatusText = styled.span`
  margin-right: 3px;
  text-transform: capitalize;
`

const Logo = styled.img`
  width: 15px;
  object-fit: scale-down;
  margin-left: 3px;
`

const Status = ({ status, width, text='' }) => {
  const medium = 700
  return (
    <StatusContainer status={status}>
      {width && width >= medium && <StatusText>{text ? text : status}</StatusText>}
      <Logo src={getStatusIcon(status)} />
    </StatusContainer>
  )
}

const getStatusIcon = status => {
  switch (status) {
    case 'fail' || 'reverted':
      return fail
    case 'success':
      return success
    case 'attempt':
      return attempt
    default:
      return null
  }
}

export default Status
