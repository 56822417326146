// eslint-disable-next-line no-unused-vars
import { Chart, Bar } from 'react-chartjs-2'

const colors = {
  purple: {
    default: 'rgba(111, 92, 230, 1)',
    half: 'rgba(111, 92, 230, 0.5)',
    quarter: 'rgba(111, 92, 230, 0.25)',
    zero: 'rgba(111, 92, 230, 0)',
  },
}

const options = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      color: '#A7ACC1'
    },
    tooltip: {
      backgroundColor: '#6F5CE6',
      bodyFontColor: '#fff',
      titleFontColor: '#fff',
      titleFont: {
        weight: 'bold',
        size: 18,
      },
      bodyFont: {
        size: 15,
        weight: 'normal',
      },
      bodySpacing: 13,
      cornerRadius: 8,
      padding: 15,
      caretPadding: 17,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      stacked: false,
      ticks: {
        color: '#A7ACC1',
      },
    },
    y: {
      beginAtZero: true,
      type: 'linear',
      ticks: {
        color: 'transparent',
      },
    },
    'tasks-y-axis': {
      position: 'left',
      title: {
        display: true,
        text: 'Tasks Completed',
        color: '#A7ACC1',
      },
      ticks: {
        color: '#A7ACC1',
      },
    },
  },
  minBarThickness: 7,
  maxBarThickness: 15,
}

const TasksChart = ({ data }) => {
  const lineData = {}

  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(data)) {
    for (const [date, { count }] of Object.entries(value)) {
      if (lineData[date]) {
        lineData[date] += count
      } else {
        lineData[date] = count
      }
    }
  }

  const processData = () => {
    let allDateLabels = []
    Object.values(data).forEach((dates) => {
      const currentDates = Object.keys(dates)
      allDateLabels = Array.from(new Set([...allDateLabels, ...currentDates]))
    })

    allDateLabels.sort()

    return { dateLabels: allDateLabels }
  }

  const { dateLabels } = processData()


  const chartData = {
    labels: dateLabels,
    datasets: [
      {
        type: 'line',
        fill: true,
        label: 'Tasks Completed',
        yAxisID: 'tasks-y-axis',
        data: Object.values(lineData),
        borderColor: colors.purple.default,
        backgroundColor: colors.purple.half,
        pointBackgroundColor: colors.purple.default,
        lineTension: 0.8,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: '#6f5ce6',
        pointHoverBorderColor: '#6f5ce6',
      },
    ],
  }
  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default TasksChart
