import React from 'react'

function Home({ fill = '#A288DF', opacity = 1 }) {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6177 7.62358L19.0308 6.54349C18.5343 5.62957 17.4341 5.31429 16.5712 5.83869V5.83869C16.1604 6.09531 15.6703 6.16812 15.2089 6.04106C14.7475 5.91399 14.3527 5.59749 14.1116 5.16134C13.9564 4.88412 13.8731 4.56836 13.8699 4.24601V4.24601C13.8839 3.72919 13.7001 3.22837 13.3603 2.85764C13.0205 2.48691 12.5537 2.27783 12.0662 2.27805H10.8838C10.4063 2.27804 9.94835 2.47988 9.61145 2.83891C9.27456 3.19795 9.08645 3.68456 9.08874 4.19109V4.19109C9.07459 5.23689 8.27116 6.07678 7.28502 6.07667C6.98108 6.07332 6.68337 5.98491 6.42199 5.82038V5.82038C5.55911 5.29598 4.45885 5.61126 3.96236 6.52519L3.33235 7.62358C2.83647 8.53636 3.12969 9.70258 3.98825 10.2323V10.2323C4.54633 10.574 4.89013 11.2056 4.89013 11.889C4.89013 12.5725 4.54633 13.204 3.98825 13.5458V13.5458C3.13078 14.0719 2.83724 15.2353 3.33235 16.1453V16.1453L3.92784 17.2346C4.16046 17.6798 4.55077 18.0083 5.01238 18.1474C5.47399 18.2866 5.96886 18.2249 6.38747 17.976V17.976C6.79899 17.7213 7.28937 17.6515 7.74963 17.7822C8.20989 17.9128 8.60188 18.233 8.83847 18.6716C8.9936 18.9489 9.07696 19.2646 9.08011 19.587V19.587C9.08011 20.6435 9.88767 21.5 10.8838 21.5H12.0662C13.059 21.5 13.8652 20.6491 13.8699 19.5961V19.5961C13.8676 19.088 14.0569 18.6 14.3957 18.2407C14.7344 17.8814 15.1946 17.6806 15.6736 17.6831C15.9768 17.6917 16.2733 17.7797 16.5367 17.9394V17.9394C17.3973 18.4653 18.4969 18.1543 18.9963 17.2437V17.2437L19.6177 16.1453C19.8582 15.7075 19.9242 15.186 19.8011 14.6963C19.678 14.2067 19.3759 13.7893 18.9618 13.5366V13.5366C18.5476 13.2839 18.2456 12.8665 18.1224 12.3769C17.9993 11.8873 18.0654 11.3658 18.3059 10.9279C18.4623 10.6383 18.6887 10.3982 18.9618 10.2323V10.2323C19.8152 9.70286 20.1077 8.54346 19.6177 7.63274V7.63274V7.62358Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          cx="11.4794"
          cy="11.889"
          rx="2.48552"
          ry="2.63616"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default Home
