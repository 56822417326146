import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import minus from '../assets/minus.svg'
import plus from '../assets/plus.svg'
import up from '../assets/arrow-up.svg'
import down from '../assets/arrow-down.svg'

export const AccordionItem = ({
  handleToggle,
  active,
  header,
  id,
  content,
  mode = 'accordion',
}) => {
  const contentEl = useRef()
  const [contentHeight, setContentHeight] = useState(0)

  useEffect(
    () => {
      // Calcula la altura del contenido cuando cambia
      if (contentEl.current) {
        setContentHeight(contentEl.current.scrollHeight)
      }
    },
    [content]
  )
  return (
    <Card className="accordionCard">
      <Header onClick={() => handleToggle(id)}>
        {header}
        {mode === 'menu' ? (
          <img src={active === id ? up : down} alt="+" />
        ) : (
          <img src={active === id ? minus : plus} alt="+" />
        )}
      </Header>
      <Content
        ref={contentEl}
        className={active === id ? 'show' : ''}
        style={
          active === id ? { height: `${contentHeight}px` } : { height: '0px' }
        }
      >
        {content}
      </Content>
    </Card>
  )
}

const Card = styled.div`
  background: rgba(168, 154, 255, 0.1);
  color: ${props => props.theme.textWhite};
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 0 32px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
  th {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`

const Header = styled.div`
  img {
    height: 32px;
    margin: 17px 0px;
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.3s;
`

const Content = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;

  &.show {
    height: auto;
    border-top: 1px solid #403c52;
  }
`

export default AccordionItem
