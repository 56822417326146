import logo from '../assets/logo-min.svg'

export const PLAYERS = {
     // eslint-disable-next-line
    ['0xb3afb6db38a8e72905165c1fbb96772e63560790']: {
      name: 'Mimic Bot',
      logoUrl: logo,
      backgroundColor: '#5542A9'
    },
     // eslint-disable-next-line
    ['0x27751a0fe3bd6ebfeb04b359d97b0cf199f20d22']:{
      name: 'Mimic Collector',
      logoUrl: logo,
      backgroundColor: '#5542A9'
    },
}


export const RELAYER = '0x9E0A538749A486bbF127F8848a6f2CF4e1e92DbD'
