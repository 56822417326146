import React, { useState, useEffect } from 'react'
import { Inside } from 'use-inside'
import styled from 'styled-components'

const MENUWIDTH = 230

function Split({ primary, secondary, invert }) {
  const large = 900
  const [width, setWidth] = useState(window.innerWidth)
  const [oneColumn, setOneColumn] = useState(false)

  useEffect(
    () => {
      window.addEventListener('resize', () => setWidth(window.innerWidth))
      if (width < large) {
        setOneColumn(true)
      }
    },
    [width]
  )

  const primaryContent = (
    <Inside name="Split:primary">
      <Primary dataonecolumn={oneColumn.toString()}>{primary}</Primary>
    </Inside>
  )

  const secondaryContent = (
    <Inside name="Split:secondary">
      <Secondary className="scroll-container" dataonecolumn={oneColumn.toString()}>{secondary}</Secondary>
    </Inside>
  )

  return (
    <Inside name="Split">
      <Content dataonecolumn={oneColumn.toString()}>
        {secondaryContent}
        {primaryContent}
      </Content>
    </Inside>
  )
}

const Content = styled.div`
  display: ${props =>
    (props.dataonecolumn === 'true') === 'true' ? 'block' : 'flex'};
  width: 100%;
  height: calc(100vh - 80px);
  overflow: hidden;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`

const Primary = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  margin-left: ${props =>
    !props.dataonecolumn === 'true' ? '16' : '0'}px;
  padding-top: ${props => (props.dataonecolumn === 'true' ? '16' : '0')}px;
  width: ${props =>
    props.dataonecolumn === 'true'
      ? '100%'
      : 'calc(100% - ' + MENUWIDTH + 'px)'};
  height: ${props => (props.dataonecolumn === 'true' ? '60%' : 'auto')};
`

const Secondary = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  position: sticky;
  border-right: 1.5px solid #2c3041;
  width: ${props =>
    props.dataonecolumn === 'true' ? '100%' : MENUWIDTH + 'px'};
  margin-right: 0;
  padding-top: ${props => (props.dataonecolumn === 'true' ? 16 : 0)}px;
  height: ${props => (props.dataonecolumn === 'true' ? '35%' : 'auto')};
  overflow-y: scroll;
  scroll-snap-type: ${(props) => (props.mobile ? '' : 'y mandatory')};
  scroll-behavior: ${(props) => (props.mobile ? '' : 'smooth')};
`

export default Split
