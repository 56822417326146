import styled from 'styled-components'
import Table from '../structure/Table/Table'

export const Skeleton = styled.div`
  display: block;
  height: ${props => (props.height ? props.height : '30px')};
  width: ${props => (props.width ? props.width : '400px')};
  max-width: 100%;
  ${props =>
    props.marginbottom &&
    'margin-bottom: ' + props.marginbottom + ';'} border-radius: 8px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, transparent 50%, #212327);

  &::after {
    animation: shimmer 2s infinite;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      270deg,
      transparent 0%,
      #4d427d 50%,
      rgba(34, 29, 60, 0.05) 100%
    );
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`

export const ConfigContainer = styled.div`
  padding: 40px 15px;
  max-width: 1000px;
  margin: auto;
  @media only screen and (max-width: 700px) {
    padding: 10px 30px;
  }
`


export const ContainerTable = styled(Table)`
  background-color: rgba(168, 154, 255, 0.12);
  border-radius: 24px;
  tbody tr:hover {
    background: rgba(0, 0, 0, 0.24);
  }
  th {
    padding: 10px 5px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  td:first-child,
  th:first-child {
    padding-left: 25px;
    white-space: nowrap;
  }
  td:last-child,
  th:last-child {
    padding-right: 25px;
  }
  td {
    padding: 19px 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    vertical-align: top;
  }
  tr:last-child td {
    border-bottom: 0px solid;
  }
`

export const Details = styled.button`
  background: rgba(168, 154, 255, 0.10);
  transition: background-color 0.3s ease;
  color: white;
  border: 0px;
  padding: 10px 15px;
  border-radius: 10px;
  min-height: 45px;
  min-width: 45px;
  cursor: pointer;
  &:disabled {
    background: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
  }
  .chevron::before {
	border-style: solid;
	border-width: 0.25em 0.25em 0 0;
	content: '';
	display: inline-block;
	height: 5px;
	left: 2.5px;
	position: relative;
	top: 2.5px;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 5px;
  
}
&.zeropadding {
    padding: 0px !important;
  }

.warning {
    color: yellow;
  }

.chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}

.chevron.bottom:before {
	top: 2.5px;
	left: 0px;
	transform: rotate(135deg);
}

.chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}
`
