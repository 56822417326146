import React from 'react'

function Home({ fill = '#A288DF' }) {
  return (
    <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M19.8 12L8.48576 12M19.8 6L8.48576 6M19.8 18L8.48576 18M4.71433 12C4.71433 12.5523 4.2922 13 3.77147 13C3.25074 13 2.82861 12.5523 2.82861 12C2.82861 11.4477 3.25074 11 3.77147 11C4.2922 11 4.71433 11.4477 4.71433 12ZM4.71433 6C4.71433 6.55228 4.2922 7 3.77147 7C3.25074 7 2.82861 6.55228 2.82861 6C2.82861 5.44772 3.25074 5 3.77147 5C4.2922 5 4.71433 5.44772 4.71433 6ZM4.71433 18C4.71433 18.5523 4.2922 19 3.77147 19C3.25074 19 2.82861 18.5523 2.82861 18C2.82861 17.4477 3.25074 17 3.77147 17C4.2922 17 4.71433 17.4477 4.71433 18Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    </svg>
  )
}

export default Home
