import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { isAddress } from '../utils/web3-utils'
import { v2url } from '../constants/v2url'

function SearchBar({ value = '' }) {
  const [searchInput, setSearchInput] = useState(value)
  const navigate = useNavigate()

  const handleChange = e => {
    e.preventDefault()
    setSearchInput(e.target.value)
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    if (isAddress(searchInput)) {
      window.location.href = v2url + '/smart-vaults/eth:' + searchInput
    } else {
      navigate(`/${searchInput}/activity`)
    }
  }

  return (
    <Search onSubmit={handleOnSubmit}>
      <input
        type="search"
        placeholder="Search environment"
        onChange={handleChange}
        value={searchInput}
        required
      />
      <Submit type="submit">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 12H20M20 12L14 6M20 12L14 18"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Submit>
    </Search>
  )
}

const Submit = styled.button`
  color: white !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: solid 0px;
`

const Search = styled.form`
  color: white !important;
  position: relative;
  display: flex;
  width: 630px;
  border-radius: 30px;
  margin: 30px 0;
  border: 1px solid #5e5f65;
  background: #2c3041;
  padding: 3px 0;
  ::placeholder {
    color: white;
  }
  @media only screen and (max-width: 700px) {
    width: 90%;
  }

  input {
    padding: 10px 15px;
    outline: 0;
    width: calc(100% - 50px);
    border: 0px solid;
    background: transparent;
    color: white;
    font-size: 14px;
    font-weight: 500;
  }
`

export default SearchBar
