import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const useReports = (id, chainId, startDate, endDate, refetchInterval = 0) => {
  return useQuery(
    {
      queryKey: ['useReports', (id + 'volume'), chainId, startDate, endDate],
      queryFn: () => fetchReportData(id, chainId, startDate, endDate),
      staleTime: 1000,
      refetchInterval: false
    }
  )
}

const fetchReportData = async (id, chainId, startDate, endDate) => {
  const url = `https://api.mimic.fi/public/environments/${id}/volume`

  try {
    const params = {
      startDate: startDate,
      endDate: endDate,
      chainIds: ['1', '137', '10', '56', '42161', '43114', '8453', '10', '250'],
    }

    const { data } = await axios.get(url, {
      params: { ...params }
    })
    return data
  } catch (error) {
    if (error.response) {
      console.log('Error: ', error.response)
      return false
    }
    throw error
  }
}

export default useReports
