import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const useFailedReason = (hash, chainId, index, refetchInterval = 0) => {
  return useQuery(
    {
      queryKey: ['failedReason', hash, chainId, index],
      queryFn: () => fetchFailedReason(hash, chainId, index),
      staleTime: 1000,
      refetchInterval: refetchInterval
    }
  )
}

const fetchFailedReason = async (hash, chainId, index) => {
  const url = 'https://api.mimic.fi/public/task-executions'

  try {
    const params = {
      hash: hash,
      chainId: chainId,
      index: index

    }

    const { data } = await axios.get(url, {
      params: { ...params }
    })

    return data
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log('No reason')
      return false
    }
    throw error
  }
}

export default useFailedReason
