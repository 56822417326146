export const tokens = [
  {
    chainId: 1,
    address: '0xb6ed7644c69416d67b522e20bc294a9a9b405b31',
    name: '0xBitcoin',
    symbol: '0xBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/0xBTC/logo.png',
  },
  {
    chainId: 10,
    address: '0xe0BB0D3DE8c10976511e5030cA403dBf4c25165B',
    name: '0xBitcoin',
    symbol: '0xBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/0xBTC/logo.png',
  },
  {
    chainId: 250,
    address: '0x1B6382DBDEa11d97f24495C9A90b7c88469134a4',
    name: 'Axelar Wrapped USDC',
    symbol: 'axlUSDC',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
  },
  {
    chainId: 42,
    address: '0x24a19ee5a5c8757acdebe542a9436d9c796d1c9e',
    name: '0xBitcoin',
    symbol: '0xBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/0xBTC/logo.png',
  },
  {
    chainId: 69,
    address: '0x56B4F5f44d348EC3F07Bf1f31A3B566E5304BedE',
    name: '0xBitcoin',
    symbol: '0xBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/0xBTC/logo.png',
  },
  {
    chainId: 1,
    address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    name: 'Aave Token',
    symbol: 'AAVE',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/AAVE/logo.svg',
  },
  {
    chainId: 10,
    address: '0x76FB31fb4af56892A25e32cFC43De717950c9278',
    name: 'Aave Token',
    symbol: 'AAVE',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/AAVE/logo.svg',
  },
  {
    chainId: 42161,
    address: '0xa1150db5105987CEC5Fd092273d1e3cbb22b378b',
    name: 'Oath Token',
    symbol: 'OATH',
    decimals: 18,
    logoURI: 'https://arbiscan.io/token/images/bytemasons_32.png',
  },
  {
    chainId: 42,
    address: '0x9BE876c6DC42215B00d7efe892E2691C3bc35d10',
    name: 'Aave Token',
    symbol: 'AAVE',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/AAVE/logo.svg',
  },
  {
    chainId: 69,
    address: '0x76FB31fb4af56892A25e32cFC43De717950c9278',
    name: 'Aave Token',
    symbol: 'AAVE',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/AAVE/logo.svg',
  },
  {
    chainId: 1,
    address: '0x2b95a1dcc3d405535f9ed33c219ab38e8d7e0884',
    name: 'AladdinCRV',
    symbol: 'aCRV',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/aCRV/logo.svg',
  },
  {
    chainId: 10,
    address: '0x764ad60e1b81f6cacfec1a2926393d688d4493e6',
    name: 'AladdinCRV',
    symbol: 'aCRV',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/aCRV/logo.svg',
  },
  {
    chainId: 42,
    address: '0x12b1326459d72f2ab081116bf27ca46cd97762a0',
    name: 'AladdinCRV',
    symbol: 'aCRV',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/aCRV/logo.svg',
  },
  {
    chainId: 69,
    address: '0xf882ac9259a378e102f058f2fb093dffeca96437',
    name: 'AladdinCRV',
    symbol: 'aCRV',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/aCRV/logo.svg',
  },
  {
    chainId: 1,
    address: '0x44108f0223A3C3028F5Fe7AEC7f9bb2E66beF82F',
    name: 'Across Protocol Token',
    symbol: 'ACX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ACX/logo.png',
  },
  {
    chainId: 10,
    address: '0xff733b2a3557a7ed6697007ab5d11b79fdd1b76b',
    name: 'Across Protocol Token',
    symbol: 'ACX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ACX/logo.png',
  },
  {
    chainId: 10,
    address: '0x61BAADcF22d2565B0F471b291C475db5555e0b76',
    name: 'Aelin Protocol',
    symbol: 'AELIN',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/AELIN/logo.png',

  },
  {
    chainId: 1,
    address: '0x0100546f2cd4c9d97f798ffc9755e47865ff7ee6',
    name: 'Alchemix ETH',
    symbol: 'alETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/alETH/logo.svg',

  },
  {
    chainId: 10,
    address: '0x3e29d3a9316dab217754d13b28646b76607c5f04',
    name: 'Alchemix ETH',
    symbol: 'alETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/alETH/logo.svg',

  },
  {
    chainId: 1,
    address: '0xba100000625a3754423978a60c9317c58a424e3D',
    name: 'Balancer',
    symbol: 'BAL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BAL/logo.png',
  },
  {
    chainId: 10,
    address: '0xFE8B128bA8C78aabC59d4c64cEE7fF28e9379921',
    name: 'Balancer',
    symbol: 'BAL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BAL/logo.png',
  },
  {
    chainId: 42,
    address: '0x41286Bb1D3E870f3F750eB7E1C25d7E48c8A1Ac7',
    name: 'Balancer',
    symbol: 'BAL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BAL/logo.png',
  },
  {
    chainId: 69,
    address: '0xc72751efd79B153D5bdC7E1a43b4b98aA2aA04C7',
    name: 'Balancer',
    symbol: 'BAL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BAL/logo.png',
  },
  {
    chainId: 1,
    address: '0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198',
    name: 'Bankless Token',
    symbol: 'BANK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BANK/logo.png',
  },
  {
    chainId: 10,
    address: '0x29FAF5905bfF9Cfcc7CF56a5ed91E0f091F8664B',
    name: 'Bankless Token',
    symbol: 'BANK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BANK/logo.png',
  },
  {
    chainId: 1,
    address: '0x91B08f4A7C1251dfCcF5440F8894F8DaA10c8De5',
    name: 'BAXagent Coin',
    symbol: 'BAXA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BAXA/logo.svg',
  },
  {
    chainId: 10,
    address: '0xDb9888b842408B0b8eFa1f5477bD9F351754999E',
    name: 'BAXagent Coin',
    symbol: 'BAXA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BAXA/logo.svg',
  },
  {
    chainId: 42,
    address: '0x0Fc18b0CB89611C03Fc1Ba6876bdfE22317924FE',
    name: 'BAXagent Coin',
    symbol: 'BAXA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BAXA/logo.svg',
  },
  {
    chainId: 69,
    address: '0xD7eA82D19f1f59FF1aE95F1945Ee6E6d86A25B96',
    name: 'BAXagent Coin',
    symbol: 'BAXA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BAXA/logo.svg',
  },
  {
    chainId: 1,
    address: '0x15Ee120fD69BEc86C1d38502299af7366a41D1a6',
    name: 'BitANT',
    symbol: 'BitANT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BitANT/logo.png',
  },
  {
    chainId: 10,
    address: '0x5029C236320b8f15eF0a657054B84d90bfBEDED3',
    name: 'BitANT',
    symbol: 'BitANT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BitANT/logo.png',
  },
  {
    chainId: 42,
    address: '0x3Ef0aBa205134ba2f449Fa04A0A0673020c36270',
    name: 'BitANT',
    symbol: 'BitANT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BitANT/logo.png',
  },
  {
    chainId: 69,
    address: '0x1f748732AF4442Cf508DEf0882ad9fcB5E5205a2',
    name: 'BitANT',
    symbol: 'BitANT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BitANT/logo.png',
  },
  {
    chainId: 1,
    address: '0x3C513dB8Bdc3806e4489d62C3d549A5Aaf6A4e97',
    name: 'BitBTC',
    symbol: 'BitBTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BitBTC/logo.png',
  },
  {
    chainId: 10,
    address: '0xc98B98d17435AA00830c87eA02474C5007E1f272',
    name: 'BitBTC',
    symbol: 'BitBTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BitBTC/logo.png',
  },
  {
    chainId: 42,
    address: '0xF6DD2A9B840826D53c9842207628502B79E6B8c2',
    name: 'BitBTC',
    symbol: 'BitBTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BitBTC/logo.png',
  },
  {
    chainId: 69,
    address: '0x83643C9eF0c5707A7815124754d0828c9A38be3a',
    name: 'BitBTC',
    symbol: 'BitBTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BitBTC/logo.png',
  },
  {
    chainId: 1,
    address: '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
    name: 'BOB Token',
    symbol: 'BOB',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BOB/logo.svg',

  },
  {
    chainId: 10,
    address: '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
    name: 'BOB Token',
    symbol: 'BOB',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BOB/logo.svg',

  },
  {
    chainId: 1,
    address: '0x0391D2021f89DC339F60Fff84546EA23E337750f',
    name: 'BarnBridge Governance Token',
    symbol: 'BOND',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BOND/logo.svg',
  },
  {
    chainId: 10,
    address: '0x3e7eF8f50246f725885102E8238CBba33F276747',
    name: 'BarnBridge Governance Token',
    symbol: 'BOND',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BOND/logo.svg',
  },
  {
    chainId: 42,
    address: '0x521EE0CeDbed2a5A130B9218551fe492C5c402e4',
    name: 'BarnBridge Governance Token',
    symbol: 'BOND',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BOND/logo.svg',
  },
  {
    chainId: 69,
    address: '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
    name: 'BarnBridge Governance Token',
    symbol: 'BOND',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/BOND/logo.svg',
  },
  {
    chainId: 1,
    address: '0xbe9895146f7af43049ca1c1ae358b0541ea49704',
    name: 'Coinbase Wrapped Staked ETH',
    symbol: 'cbETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/cbETH/logo.svg',
  },
  {
    chainId: 10,
    address: '0xaddb6a0412de1ba0f936dcaeb8aaa24578dcf3b2',
    name: 'Coinbase Wrapped Staked ETH',
    symbol: 'cbETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/cbETH/logo.svg',
  },
  {
    chainId: 5,
    address: '0xD0bb78d0B337aA6D3A0530DD2e58560bf00851f1',
    name: 'Coinbase Wrapped Staked ETH',
    symbol: 'cbETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/cbETH/logo.svg',
  },
  {
    chainId: 420,
    address: '0xd6909e9e702024eb93312B989ee46794c0fB1C9D',
    name: 'Coinbase Wrapped Staked ETH',
    symbol: 'cbETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/cbETH/logo.svg',
  },
  {
    chainId: 1,
    address: '0x8B21e9b7dAF2c4325bf3D18c1BeB79A347fE902A',
    name: 'Collab.Land',
    symbol: 'COLLAB',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/COLLAB/logo.png',
  },
  {
    chainId: 10,
    address: '0x8B21e9b7dAF2c4325bf3D18c1BeB79A347fE902A',
    name: 'Collab.Land',
    symbol: 'COLLAB',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/COLLAB/logo.png',
  },
  {
    chainId: 1,
    address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    name: 'Curve DAO Token',
    symbol: 'CRV',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/CRV/logo.png',
  },
  {
    chainId: 10,
    address: '0x0994206dfe8de6ec6920ff4d779b0d950605fb53',
    name: 'Curve DAO Token',
    symbol: 'CRV',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/CRV/logo.png',
  },
  {
    chainId: 1,
    address: '0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D',
    name: 'Cartesi Token',
    symbol: 'CTSI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/CTSI/logo.svg',
  },
  {
    chainId: 10,
    address: '0xEc6adef5E1006bb305bB1975333e8fc4071295bf',
    name: 'Cartesi Token',
    symbol: 'CTSI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/CTSI/logo.svg',
  },
  {
    chainId: 69,
    address: '0xEc6adef5E1006bb305bB1975333e8fc4071295bf',
    name: 'Cartesi Token',
    symbol: 'CTSI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/CTSI/logo.svg',
  },
  {
    chainId: 1,
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
  },
  {
    chainId: 10,
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
  },
  {
    chainId: 42,
    address: '0x4f96fe3b7a6cf9725f59d353f723c1bdb64ca6aa',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
  },
  {
    chainId: 69,
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
  },
  {
    chainId: 5,
    address: '0x11fE4B6AE13d2a6055C8D9cF65c55bac32B5d844',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
  },
  {
    chainId: 420,
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
  },
  {
    chainId: 1,
    address: '0x08d32b0da63e2C3bcF8019c9c5d849d7a9d791e6',
    name: 'Dentacoin',
    symbol: 'DCN',
    decimals: 0,
    logoURI: 'https://ethereum-optimism.github.io/data/DCN/logo.svg',
  },
  {
    chainId: 10,
    address: '0x1da650c3b2daa8aa9ff6f661d4156ce24d08a062',
    name: 'Dentacoin',
    symbol: 'DCN',
    decimals: 0,
    logoURI: 'https://ethereum-optimism.github.io/data/DCN/logo.svg',
  },
  {
    chainId: 42,
    address: '0x001dedC8c67EF6D1f4BB66FB28581d466650Cd76',
    name: 'Dentacoin',
    symbol: 'DCN',
    decimals: 0,
    logoURI: 'https://ethereum-optimism.github.io/data/DCN/logo.svg',
  },
  {
    chainId: 69,
    address: '0xfd6ab60e0744e95c35fb17afda478eeae3835ddf',
    name: 'Dentacoin',
    symbol: 'DCN',
    decimals: 0,
    logoURI: 'https://ethereum-optimism.github.io/data/DCN/logo.svg',
  },
  {
    chainId: 1,
    address: '0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0',
    name: 'dForce',
    symbol: 'DF',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DF/logo.svg',
  },
  {
    chainId: 10,
    address: '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
    name: 'dForce',
    symbol: 'DF',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DF/logo.svg',
  },
  {
    chainId: 42,
    address: '0x79E40d67DA6eAE5eB4A93Fc6a56A7961625E15F3',
    name: 'dForce',
    symbol: 'DF',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DF/logo.svg',
  },
  {
    chainId: 69,
    address: '0x3e7eF8f50246f725885102E8238CBba33F276747',
    name: 'dForce',
    symbol: 'DF',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DF/logo.svg',
  },
  {
    chainId: 1,
    address: '0xca1207647Ff814039530D7d35df0e1Dd2e91Fa84',
    name: 'dHEDGE DAO Token',
    symbol: 'DHT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DHT/logo.svg',
  },
  {
    chainId: 10,
    address: '0xAF9fE3B5cCDAe78188B1F8b9a49Da7ae9510F151',
    name: 'dHEDGE DAO Token',
    symbol: 'DHT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DHT/logo.svg',
  },
  {
    chainId: 1,
    address: '0x865377367054516e17014CcdED1e7d814EDC9ce4',
    name: 'Dola USD Stablecoin',
    symbol: 'DOLA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DOLA/logo.png',
  },
  {
    chainId: 10,
    address: '0x8aE125E8653821E851F12A49F7765db9a9ce7384',
    name: 'Dola USD Stablecoin',
    symbol: 'DOLA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DOLA/logo.png',
  },
  {
    chainId: 42,
    address: '0x39445DEc9Ba7Fb3776E8E5f9922864ddb9089304',
    name: 'Dola USD Stablecoin',
    symbol: 'DOLA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DOLA/logo.png',
  },
  {
    chainId: 69,
    address: '0x0D760ee479401Bb4C40BDB7604b329FfF411b3f2',
    name: 'Dola USD Stablecoin',
    symbol: 'DOLA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DOLA/logo.png',
  },
  {
    chainId: 1,
    address: '0x92E187a03B6CD19CB6AF293ba17F2745Fd2357D5',
    name: 'Unit protocol',
    symbol: 'DUCK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DUCK/logo.png',
  },
  {
    chainId: 10,
    address: '0x0e49ca6ea763190084c846d3fc18f28bc2ac689a',
    name: 'Unit protocol',
    symbol: 'DUCK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/DUCK/logo.png',
  },

  {
    chainId: 1,
    address: '0x0000000000000000000000000000000000000000',
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
  },
  {
    chainId: 10,
    address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
  },
  {
    chainId: 42,
    address: '0x0000000000000000000000000000000000000000',
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
  },
  {
    chainId: 5,
    address: '0x0000000000000000000000000000000000000000',
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
  },
  {
    chainId: 69,
    address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
  },
  {
    chainId: 420,
    address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
  },
  {
    chainId: 1,
    address: '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
    name: 'Ethereum Name Service',
    symbol: 'ENS',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ENS/logo.png',
  },
  {
    chainId: 10,
    address: '0x65559aA14915a70190438eF90104769e5E890A00',
    name: 'Ethereum Name Service',
    symbol: 'ENS',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ENS/logo.png',
  },
  {
    chainId: 1,
    address: '0x1da87b114f35e1dc91f72bf57fc07a768ad40bb0',
    name: 'Equalizer',
    symbol: 'EQZ',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/EQZ/logo.png',
  },
  {
    chainId: 10,
    address: '0x81ab7e0d570b01411fcc4afd3d50ec8c241cb74b',
    name: 'Equalizer',
    symbol: 'EQZ',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/EQZ/logo.png',
  },
  {
    chainId: 42,
    address: '0xea281a04cf517aa0d4645bdda0353b0958e4b1b4',
    name: 'Equalizer',
    symbol: 'EQZ',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/EQZ/logo.png',
  },
  {
    chainId: 69,
    address: '0x8ee73c484a26e0a5df2ee2a4960b789967dd0415',
    name: 'Equalizer',
    symbol: 'EQZ',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/EQZ/logo.png',
  },
  {
    chainId: 1,
    address: '0x259ab9b9eab62b0fd98729b97be121073d5b3479',
    name: 'Erica Social Token',
    symbol: 'EST',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/EST/logo.png',
  },
  {
    chainId: 10,
    address: '0x7b0bcC23851bBF7601efC9E9FE532Bf5284F65d3',
    name: 'Erica Social Token',
    symbol: 'EST',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/EST/logo.png',
  },
  {
    chainId: 42,
    address: '0x50EB44e3a68f1963278b4c74c6c343508d31704C',
    name: 'Tether EUR',
    symbol: 'EURT',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/EURT/logo.png',
  },
  {
    chainId: 69,
    address: '0x65e44970ebFe42f98F83c4B67062DE94B9f3Da7D',
    name: 'Tether EUR',
    symbol: 'EURT',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/EURT/logo.png',
  },
  {
    chainId: 1,
    address: '0xB1f1F47061A7Be15C69f378CB3f69423bD58F2F8',
    name: 'Flashstake',
    symbol: 'FLASH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/FLASH/logo.svg',
  },
  {
    chainId: 10,
    address: '0x86bEA60374f220dE9769b2fEf2db725bc1cDd335',
    name: 'Flashstake',
    symbol: 'FLASH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/FLASH/logo.svg',
  },
  {
    chainId: 1,
    address: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
    name: 'FRAX',
    symbol: 'FRAX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/FRAX/logo.svg',

  },
  {
    chainId: 10,
    address: '0x2E3D870790dC77A83DD1d18184Acc7439A53f475',
    name: 'FRAX',
    symbol: 'FRAX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/FRAX/logo.svg',

  },
  {
    chainId: 1,
    address: '0x5e8422345238f34275888049021821e8e08caa1f',
    name: 'Frax Ether',
    symbol: 'frxETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/frxETH/logo.svg',
  },
  {
    chainId: 10,
    address: '0x6806411765Af15Bddd26f8f544A34cC40cb9838B',
    name: 'Frax Ether',
    symbol: 'frxETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/frxETH/logo.svg',
  },
  {
    chainId: 5,
    address: '0x5e8422345238f34275888049021821e8e08caa1f',
    name: 'Frax Ether',
    symbol: 'frxETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/frxETH/logo.svg',
  },
  {
    chainId: 1,
    address: '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
    name: 'FXS',
    symbol: 'FXS',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/FXS/logo.svg',

  },
  {
    chainId: 10,
    address: '0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be',
    name: 'FXS',
    symbol: 'FXS',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/FXS/logo.svg',

  },
  {
    chainId: 1,
    address: '0x4FbB350052Bca5417566f188eB2EBCE5b19BC964',
    name: 'Rigo Token',
    symbol: 'GRG',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GRG/logo.svg',
  },
  {
    chainId: 10,
    address: '0xEcF46257ed31c329F204Eb43E254C609dee143B3',
    name: 'Rigo Token',
    symbol: 'GRG',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GRG/logo.svg',
  },
  {
    chainId: 1,
    address: '0xde30da39c46104798bb5aa3fe8b9e0e1f348163f',
    name: 'Gitcoin',
    symbol: 'GTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GTC/logo.svg',
  },
  {
    chainId: 10,
    address: '0x1eba7a6a72c894026cd654ac5cdcf83a46445b08',
    name: 'Gitcoin',
    symbol: 'GTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GTC/logo.svg',
  },
  {
    chainId: 42,
    address: '0xb7e230f904971724c600ad5217b88d219ddd1525',
    name: 'Gitcoin',
    symbol: 'GTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GTC/logo.svg',
  },
  {
    chainId: 69,
    address: '0xaf8ca653fa2772d58f4368b0a71980e9e3ceb888',
    name: 'Gitcoin',
    symbol: 'GTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GTC/logo.svg',
  },
  {
    chainId: 1,
    address: '0xC08512927D12348F6620a698105e1BAac6EcD911',
    name: 'GMO JPY',
    symbol: 'GYEN',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/GYEN/logo.svg',
  },
  {
    chainId: 10,
    address: '0x589d35656641d6aB57A545F08cf473eCD9B6D5F7',
    name: 'GMO JPY',
    symbol: 'GYEN',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/GYEN/logo.svg',
  },
  {
    chainId: 1,
    address: '0xbEa98c05eEAe2f3bC8c3565Db7551Eb738c8CCAb',
    name: 'Geyser',
    symbol: 'GYSR',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GYSR/logo.png',
  },
  {
    chainId: 10,
    address: '0x117cFd9060525452db4A34d51c0b3b7599087f05',
    name: 'Geyser',
    symbol: 'GYSR',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GYSR/logo.png',
  },
  {
    chainId: 42,
    address: '0xDA9b55DE6e04404F6c77673D4B243142a4efC6B8',
    name: 'Geyser',
    symbol: 'GYSR',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GYSR/logo.png',
  },
  {
    chainId: 69,
    address: '0x197D38DC562DfB2490eC1A1d5C4CC4319d178Bb4',
    name: 'Geyser',
    symbol: 'GYSR',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/GYSR/logo.png',
  },
  {
    chainId: 1,
    address: '0x9ce115f0341ae5dabc8b477b74e83db2018a6f42',
    name: 'HairDAO Token',
    symbol: 'HAIR',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HAIR/logo.svg',
  },
  {
    chainId: 10,
    address: '0x5465145a47260D5E715733997333A175D97285Bb',
    name: 'HairDAO Token',
    symbol: 'HAIR',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HAIR/logo.svg',
  },
  {
    chainId: 1,
    address: '0x0c90C57aaf95A3A87eadda6ec3974c99D786511F',
    name: 'HanChain',
    symbol: 'HAN',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HAN/logo.svg',
  },
  {
    chainId: 10,
    address: '0x50Bce64397C75488465253c0A034b8097FeA6578',
    name: 'HanChain',
    symbol: 'HAN',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HAN/logo.svg',
  },
  {
    chainId: 5,
    address: '0x0c90C57aaf95A3A87eadda6ec3974c99D786511F',
    name: 'HanChain',
    symbol: 'HAN',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HAN/logo.svg',
  },
  {
    chainId: 420,
    address: '0x9e1028f5f1d5ede59748ffcee5532509976840e0',
    name: 'HanChain',
    symbol: 'HAN',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HAN/logo.svg',
  },
  {
    chainId: 1,
    address: '0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc',
    name: 'HOP',
    symbol: 'HOP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HOP/logo.png',

  },
  {
    chainId: 10,
    address: '0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc',
    name: 'HOP',
    symbol: 'HOP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HOP/logo.png',

  },
  {
    chainId: 5,
    address: '0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc',
    name: 'HOP',
    symbol: 'HOP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HOP/logo.png',

  },
  {
    chainId: 420,
    address: '0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc',
    name: 'HOP',
    symbol: 'HOP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/HOP/logo.png',

  },
  {
    chainId: 1,
    address: '0xF655C8567E0f213e6C634CD2A68d992152161dC6',
    name: 'Impermax',
    symbol: 'IBEX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/IBEX/logo.png',
  },
  {
    chainId: 10,
    address: '0xBB6BBaa0F6D839A00c82B10747aBc3b7E2eEcc82',
    name: 'Impermax',
    symbol: 'IBEX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/IBEX/logo.png',
  },
  {
    chainId: 5,
    address: '0x0C4a63D472120d7859E2842b7C2Bafbd8eDe8f44',
    name: 'InsureToken',
    symbol: 'INSURE',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/INSURE/logo.png',
  },
  {
    chainId: 420,
    address: '0xeF26Ca2844b0b470AfC1b0aeF5F65e677dE6CD1f',
    name: 'InsureToken',
    symbol: 'INSURE',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/INSURE/logo.png',
  },
  {
    chainId: 1,
    address: '0x8a9c67fee641579deba04928c4bc45f66e26343a',
    name: 'Jarvis Network Token',
    symbol: 'JRT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/JRT/logo.svg',
  },
  {
    chainId: 1,
    address: '0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202',
    name: 'Kyber Network Crystal v2',
    symbol: 'KNC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/KNC/logo.png',
  },
  {
    chainId: 10,
    address: '0xa00E3A3511aAC35cA78530c85007AFCd31753819',
    name: 'Kyber Network Crystal v2',
    symbol: 'KNC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/KNC/logo.png',
  },
  {
    chainId: 42,
    address: '0x4BC18Ae212F798FD7b0eae94dfCA18c0333157c4',
    name: 'Kyber Network Crystal v2',
    symbol: 'KNC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/KNC/logo.png',
  },
  {
    chainId: 69,
    address: '0x83251BD7EbB55f9CED617030107cE32ef06F54f8',
    name: 'Kyber Network Crystal v2',
    symbol: 'KNC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/KNC/logo.png',
  },
  {
    chainId: 1,
    address: '0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789',
    name: 'Kromatika',
    symbol: 'KROM',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/KROM/logo.png',
  },
  {
    chainId: 10,
    address: '0xf98dcd95217e15e05d8638da4c91125e59590b07',
    name: 'Kromatika',
    symbol: 'KROM',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/KROM/logo.png',
  },
  {
    chainId: 42,
    address: '0x0436F489525c47C1A4877a06bE0BEaba2A2E2e2d',
    name: 'Kromatika',
    symbol: 'KROM',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/KROM/logo.png',
  },
  {
    chainId: 69,
    address: '0x9e1028F5F1D5eDE59748FFceE5532509976840E0',
    name: 'Kromatika',
    symbol: 'KROM',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/KROM/logo.png',
  },
  {
    chainId: 42,
    address: '0xC13DD39606d4C2489ce5D9806d060FeD9a7d063d',
    name: 'Land',
    symbol: 'LAND',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LAND/logo.png',
  },
  {
    chainId: 69,
    address: '0x2E2015aa4ac40B083189A03a75AD229dfB10172c',
    name: 'Land',
    symbol: 'LAND',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LAND/logo.png',
  },
  {
    chainId: 1,
    address: '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
    name: 'Lido DAO Token',
    symbol: 'LDO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LDO/logo.svg',
  },
  {
    chainId: 10,
    address: '0xFdb794692724153d1488CcdBE0C56c252596735F',
    name: 'Lido DAO Token',
    symbol: 'LDO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LDO/logo.svg',
  },
  {
    chainId: 5,
    address: '0x56340274fB5a72af1A3C6609061c451De7961Bd4',
    name: 'TEST Lido DAO Token',
    symbol: 'TESTLDO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LDO/logo.svg',
  },
  {
    chainId: 420,
    address: '0xE265FC71d45fd791c9ebf3EE0a53FBB220Eb8f75',
    name: 'TEST Lido DAO Token',
    symbol: 'TESTLDO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LDO/logo.svg',
  },
  {
    chainId: 1,
    address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    name: 'Chainlink',
    symbol: 'LINK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LINK/logo.png',
  },
  {
    chainId: 10,
    address: '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6',
    name: 'Chainlink',
    symbol: 'LINK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LINK/logo.png',
  },
  {
    chainId: 42,
    address: '0xa36085F69e2889c224210F603D836748e7dC0088',
    name: 'Chainlink',
    symbol: 'LINK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LINK/logo.png',
  },
  {
    chainId: 69,
    address: '0x4911b761993b9c8c0d14Ba2d86902AF6B0074F5B',
    name: 'Chainlink',
    symbol: 'LINK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LINK/logo.png',
  },
  {
    chainId: 5,
    address: '0x326C977E6efc84E512bB9C30f76E30c160eD06FB',
    name: 'Chainlink',
    symbol: 'LINK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LINK/logo.png',
  },
  {
    chainId: 420,
    address: '0xdc2CC710e42857672E7907CF474a69B63B93089f',
    name: 'Chainlink',
    symbol: 'LINK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LINK/logo.png',
  },
  {
    chainId: 1,
    address: '0x102e941b77bcaa7e35d368cafe51ef8f79c8d1ef',
    name: 'Theranos Coin',
    symbol: 'LIZ',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LIZ/logo.png',
  },
  {
    chainId: 10,
    address: '0x3bB4445D30AC020a84c1b5A8A2C6248ebC9779D0',
    name: 'Theranos Coin',
    symbol: 'LIZ',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LIZ/logo.png',
  },
  {
    chainId: 42,
    address: '0x47Aa2a683b88E23c4d76f91Aa4181A59d0E4FBfb',
    name: 'Theranos Coin',
    symbol: 'LIZ',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LIZ/logo.png',
  },
  {
    chainId: 69,
    address: '0x3bb4445d30ac020a84c1b5a8a2c6248ebc9779d0',
    name: 'Theranos Coin',
    symbol: 'LIZ',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LIZ/logo.png',
  },
  {
    chainId: 1,
    address: '0x3650B69f86cB593f116e276C30666834336c0647',
    name: 'Loopfi',
    symbol: 'LPF',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LPF/logo.svg',
  },
  {
    chainId: 10,
    address: '0x0B3e851cf6508A16266BC68A651ea68b31ef673b',
    name: 'Loopfi',
    symbol: 'LPF',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LPF/logo.svg',
  },
  {
    chainId: 1,
    address: '0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD',
    name: 'LoopringCoin V2',
    symbol: 'LRC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LRC/logo.png',
  },
  {
    chainId: 10,
    address: '0xFEaA9194F9F8c1B65429E31341a103071464907E',
    name: 'LoopringCoin V2',
    symbol: 'LRC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LRC/logo.png',
  },
  {
    chainId: 1,
    address: '0x5f98805a4e8be255a32880fdec7f6728c6568ba0',
    name: 'LUSD Stablecoin',
    symbol: 'LUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LUSD/logo.svg',
  },
  {
    chainId: 10,
    address: '0xc40f949f8a4e094d1b49a23ea9241d289b7b2819',
    name: 'LUSD Stablecoin',
    symbol: 'LUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LUSD/logo.svg',
  },
  {
    chainId: 1,
    address: '0x01ba67aac7f75f647d94220cc98fb30fcc5105bf',
    name: 'Lyra',
    symbol: 'LYRA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LYRA/logo.png',
  },
  {
    chainId: 10,
    address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
    name: 'Lyra',
    symbol: 'LYRA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/LYRA/logo.png',
  },
  {
    chainId: 1,
    address: '0x69af81e73a73b40adf4f3d4223cd9b1ece623074',
    name: 'Mask Network',
    symbol: 'MASK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MASK/logo.svg',
  },
  {
    chainId: 10,
    address: '0x3390108E913824B8eaD638444cc52B9aBdF63798',
    name: 'Mask Network',
    symbol: 'MASK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MASK/logo.svg',
  },
  {
    chainId: 1,
    address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MKR/logo.png',
  },
  {
    chainId: 10,
    address: '0xab7badef82e9fe11f6f33f87bc9bc2aa27f2fcb5',
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MKR/logo.png',
  },
  {
    chainId: 1,
    address: '0xf134519cBE2042B06Ee7cE20Df51d09b55559896',
    name: 'Mochi',
    symbol: 'MOCHI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MOCHI/logo.svg',
  },
  {
    chainId: 10,
    address: '0x77D40CBc27f912dcDbF4348cAf87B427c4D02486',
    name: 'Mochi',
    symbol: 'MOCHI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MOCHI/logo.svg',
  },
  {
    chainId: 5,
    address: '0x48B942C65a5991dD64360CdDE442792bD829Af80',
    name: 'Mochi',
    symbol: 'MOCHI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MOCHI/logo.svg',
  },
  {
    chainId: 420,
    address: '0x7Af72aD5E8793023DC722d617fe3338ac6b5ccE9',
    name: 'Mochi',
    symbol: 'MOCHI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MOCHI/logo.svg',
  },
  {
    chainId: 1,
    address: '0x1351986732367ff6B51784c6A75f63502dE13a9a',
    name: 'Monetum',
    symbol: 'MOM',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MOM/logo.svg',
  },
  {
    chainId: 10,
    address: '0x5e70AfFE232e2919792f77EB94e566db0320fa88',
    name: 'Monetum',
    symbol: 'MOM',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MOM/logo.svg',
  },
  {
    chainId: 5,
    address: '0xd3D1c79D8a5F8316D07c4A391805E5006c71648C',
    name: 'Monetum',
    symbol: 'MOM',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MOM/logo.svg',
  },
  {
    chainId: 420,
    address: '0x4621b7A9c75199271F773Ebd9A499dbd165c3191',
    name: 'Monetum',
    symbol: 'MOM',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/MOM/logo.svg',
  },
  {
    chainId: 1,
    address: '0x0f9b80fc3c8b9123D0aEf43Df58ebDBC034A8901',
    name: 'NFTEarth',
    symbol: 'NFTE',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/NFTE/logo.png',
  },
  {
    chainId: 10,
    address: '0xc96f4F893286137aC17e07Ae7F217fFca5db3AB6',
    name: 'NFTEarth',
    symbol: 'NFTE',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/NFTE/logo.png',
  },
  {
    chainId: 10,
    address: '0x4200000000000000000000000000000000000042',
    name: 'Optimism',
    symbol: 'OP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/OP/logo.png',

  },
  {
    chainId: 69,
    address: '0x4200000000000000000000000000000000000042',
    name: 'Optimism',
    symbol: 'OP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/OP/logo.png',

  },
  {
    chainId: 5,
    address: '0x32B3b2281717dA83463414af4E8CfB1970E56287',
    name: 'OptimismUselessToken-Bridged',
    symbol: 'OUTb',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/OUTb/logo.svg',
  },
  {
    chainId: 420,
    address: '0x3e7eF8f50246f725885102E8238CBba33F276747',
    name: 'OptimismUselessToken-Bridged',
    symbol: 'OUTb',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/OUTb/logo.svg',
  },
  {
    chainId: 1,
    address: '0x7ae1d57b58fa6411f32948314badd83583ee0e8c',
    name: 'Paper',
    symbol: 'PAPER',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PAPER/logo.svg',
  },
  {
    chainId: 10,
    address: '0x00F932F0FE257456b32dedA4758922E56A4F4b42',
    name: 'Paper',
    symbol: 'PAPER',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PAPER/logo.svg',
  },
  {
    chainId: 1,
    address: '0xbC396689893D065F41bc2C6EcbeE5e0085233447',
    name: 'Perpetual',
    symbol: 'PERP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PERP/logo.png',
  },
  {
    chainId: 10,
    address: '0x9e1028F5F1D5eDE59748FFceE5532509976840E0',
    name: 'Perpetual',
    symbol: 'PERP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PERP/logo.png',
  },
  {
    chainId: 42,
    address: '0x002be8a5961e0f352092d6693133a6944b7846ba',
    name: 'Perpetual',
    symbol: 'PERP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PERP/logo.png',
  },
  {
    chainId: 69,
    address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
    name: 'Perpetual',
    symbol: 'PERP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PERP/logo.png',
  },
  {
    chainId: 5,
    address: '0x29DdacF754877123157e0eeed26770736B304d0C',
    name: 'Perpetual',
    symbol: 'PERP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PERP/logo.png',
  },
  {
    chainId: 420,
    address: '0x0994206dfE8De6Ec6920FF4D779B0d950605Fb53',
    name: 'Perpetual',
    symbol: 'PERP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PERP/logo.png',
  },
  {
    chainId: 1,
    address: '0x1FEE5588cb1De19c70B6aD5399152D8C643FAe7b',
    name: 'PhunToken',
    symbol: 'PHTK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PHTK/logo.svg',
  },
  {
    chainId: 10,
    address: '0xFa956eB0c4b3E692aD5a6B2f08170aDE55999ACa',
    name: 'PhunToken',
    symbol: 'PHTK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PHTK/logo.svg',
  },
  {
    chainId: 1,
    address: '0x0cec1a9154ff802e7934fc916ed7ca50bde6844e',
    name: 'PoolTogether',
    symbol: 'POOL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/POOL/logo.svg',
  },
  {
    chainId: 10,
    address: '0x395ae52bb17aef68c2888d941736a71dc6d4e125',
    name: 'PoolTogether',
    symbol: 'POOL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/POOL/logo.svg',
  },
  {
    chainId: 1,
    address: '0xd0cd466b34a24fcb2f87676278af2005ca8a78c4',
    name: 'Popcorn',
    symbol: 'POP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/POP/logo.svg',
  },
  {
    chainId: 10,
    address: '0x6F0fecBC276de8fC69257065fE47C5a03d986394',
    name: 'Popcorn',
    symbol: 'POP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/POP/logo.svg',
  },
  {
    chainId: 1,
    address: '0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70',
    name: 'Premia',
    symbol: 'PREMIA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PREMIA/logo.svg',
  },
  {
    chainId: 10,
    address: '0x374Ad0f47F4ca39c78E5Cc54f1C9e426FF8f231A',
    name: 'Premia',
    symbol: 'PREMIA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/PREMIA/logo.svg',
  },
  {
    chainId: 1,
    address: '0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919',
    name: 'Rai Reflex Index',
    symbol: 'RAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RAI/logo.svg',
  },
  {
    chainId: 10,
    address: '0x7FB688CCf682d58f86D7e38e03f9D22e7705448B',
    name: 'Rai Reflex Index',
    symbol: 'RAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RAI/logo.svg',
  },
  {
    chainId: 42,
    address: '0x76b06a2f6dF6f0514e7BEC52a9AfB3f603b477CD',
    name: 'Rai Reflex Index',
    symbol: 'RAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RAI/logo.svg',
  },
  {
    chainId: 69,
    address: '0x743224e4822710a3e40d754244f3e0f1db2e5d8f',
    name: 'Rai Reflex Index',
    symbol: 'RAI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RAI/logo.svg',
  },
  {
    chainId: 1,
    address: '0xae78736cd615f374d3085123a210448e74fc6393',
    name: 'Rocket Pool ETH',
    symbol: 'rETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/rETH/logo.png',
  },
  {
    chainId: 10,
    address: '0x9bcef72be871e61ed4fbbc7630889bee758eb81d',
    name: 'Rocket Pool ETH',
    symbol: 'rETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/rETH/logo.png',
  },
  {
    chainId: 1,
    address: '0x07FA101efde726E0956Edd2c4D5C8d3d1A5E9c53',
    name: 'Respawn Finance Wrapped Staked Ethereum',
    symbol: 'RFWSTETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RFWSTETH/logo.svg',
  },
  {
    chainId: 10,
    address: '0xeAeAdAC73baaF4cB8B024dE9D65B2eeFa722856C',
    name: 'Respawn Finance Wrapped Staked Ethereum',
    symbol: 'RFWSTETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RFWSTETH/logo.svg',
  },
  {
    chainId: 42,
    address: '0x891C46986708b3914A00883B72564AB76817799A',
    name: 'Respawn Finance Wrapped Staked Ethereum',
    symbol: 'RFWSTETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RFWSTETH/logo.svg',
  },
  {
    chainId: 69,
    address: '0x1696baFBe18EFB141823a041E22E43d12B144407',
    name: 'Respawn Finance Wrapped Staked Ethereum',
    symbol: 'RFWSTETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RFWSTETH/logo.svg',
  },
  {
    chainId: 1,
    address: '0xd291e7a03283640fdc51b121ac401383a46cc623',
    name: 'Rari Governance Token',
    symbol: 'RGT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RGT/logo.png',
  },
  {
    chainId: 10,
    address: '0xb548f63d4405466b36c0c0ac3318a22fdcec711a',
    name: 'Rari Governance Token',
    symbol: 'RGT',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/RGT/logo.png',
  },
  {
    chainId: 1,
    address: '0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a',
    name: 'Sarcophagus',
    symbol: 'SARCO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SARCO/logo.png',
  },
  {
    chainId: 10,
    address: '0x7c6b91d9be155a6db01f749217d76ff02a7227f2',
    name: 'Sarcophagus',
    symbol: 'SARCO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SARCO/logo.png',
  },
  {
    chainId: 10,
    address: '0x298B9B95708152ff6968aafd889c6586e9169f1D',
    name: 'Synthetic Bitcoin',
    symbol: 'sBTC',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/sBTC/logo.svg',

  },
  {
    chainId: 1,
    address: '0xf1Dc500FdE233A4055e25e5BbF516372BC4F6871',
    name: 'Saddle DAO',
    symbol: 'SDL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SDL/logo.svg',
  },
  {
    chainId: 10,
    address: '0xAe31207aC34423C41576Ff59BFB4E036150f9cF7',
    name: 'Saddle DAO',
    symbol: 'SDL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SDL/logo.svg',
  },
  {
    chainId: 42,
    address: '0x2b7A5a5923ECa5C00c6572Cf3E8e08384F563F93',
    name: 'Saddle DAO',
    symbol: 'SDL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SDL/logo.svg',
  },
  {
    chainId: 69,
    address: '0xc96f4F893286137aC17e07Ae7F217fFca5db3AB6',
    name: 'Saddle DAO',
    symbol: 'SDL',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SDL/logo.svg',
  },
  {
    chainId: 10,
    address: '0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49',
    name: 'Synthetic Ether',
    symbol: 'sETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/sETH/logo.svg',

  },
  {
    chainId: 10,
    address: '0xc5Db22719A06418028A40A9B5E9A7c02959D0d08',
    name: 'Synthetic Chainlink',
    symbol: 'sLINK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/sLINK/logo.svg',

  },
  {
    chainId: 1,
    address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    name: 'Synthetix',
    symbol: 'SNX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
  },
  {
    chainId: 10,
    address: '0x8700daec35af8ff88c16bdf0418774cb3d7599b4',
    name: 'Synthetix',
    symbol: 'SNX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
  },
  {
    chainId: 42,
    address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    name: 'Synthetix',
    symbol: 'SNX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
  },
  {
    chainId: 69,
    address: '0x0064A673267696049938AA47595dD0B3C2e705A1',
    name: 'Synthetix',
    symbol: 'SNX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
  },
  {
    chainId: 5,
    address: '0x51f44ca59b867E005e48FA573Cb8df83FC7f7597',
    name: 'Synthetix',
    symbol: 'SNX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
  },
  {
    chainId: 420,
    address: '0x2E5ED97596a8368EB9E44B1f3F25B2E813845303',
    name: 'Synthetix',
    symbol: 'SNX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
  },
  {
    chainId: 1,
    address: '0x42d6622dece394b54999fbd73d108123806f6a18',
    name: 'SPANK',
    symbol: 'SPANK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SPANK/logo.png',
  },
  {
    chainId: 10,
    address: '0xcfD1D50ce23C46D3Cf6407487B2F8934e96DC8f9',
    name: 'SPANK',
    symbol: 'SPANK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SPANK/logo.png',
  },
  {
    chainId: 42,
    address: '0x2eA8f9B29AC1d70e01AE9D23d0451D2242e8d609',
    name: 'SPANK',
    symbol: 'SPANK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SPANK/logo.png',
  },
  {
    chainId: 69,
    address: '0xcfD1D50ce23C46D3Cf6407487B2F8934e96DC8f9',
    name: 'SPANK',
    symbol: 'SPANK',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SPANK/logo.png',
  },
  {
    chainId: 1,
    address: '0xcb9f85730f57732fc899fb158164b9ed60c77d49',
    name: 'Staked Lyra',
    symbol: 'stkLYRA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/stkLYRA/logo.svg',
  },
  {
    chainId: 10,
    address: '0x0F5d45a7023612e9e244fe84FAc5fCf3740d1492',
    name: 'Staked Lyra',
    symbol: 'stkLYRA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/stkLYRA/logo.svg',
  },
  {
    chainId: 1,
    address: '0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7',
    name: 'SUKU',
    symbol: 'SUKU',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SUKU/logo.png',
  },
  {
    chainId: 10,
    address: '0xef6301da234fc7b0545c6e877d3359fe0b9e50a4',
    name: 'SUKU',
    symbol: 'SUKU',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SUKU/logo.png',
  },
  {
    chainId: 42,
    address: '0xd35f5965b4b84382ca27072ff3b6b42e7053e672',
    name: 'SUKU',
    symbol: 'SUKU',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SUKU/logo.png',
  },
  {
    chainId: 69,
    address: '0x871f2f2ff935fd1ed867842ff2a7bfd051a5e527',
    name: 'SUKU',
    symbol: 'SUKU',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SUKU/logo.png',
  },
  {
    chainId: 10,
    address: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
    name: 'Synthetix USD',
    symbol: 'sUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/sUSD/logo.svg',

  },
  {
    chainId: 69,
    address: '0xaA5068dC2B3AADE533d3e52C6eeaadC6a8154c57',
    name: 'Synthetix USD',
    symbol: 'sUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/sUSD/logo.svg',

  },
  {
    chainId: 420,
    address: '0xeBaEAAD9236615542844adC5c149F86C36aD1136',
    name: 'Synthetix USD',
    symbol: 'sUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/sUSD/logo.svg',

  },
  {
    chainId: 1,
    address: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
    name: 'SushiToken',
    symbol: 'SUSHI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SUSHI/logo.svg',
  },
  {
    chainId: 10,
    address: '0x3eaEb77b03dBc0F6321AE1b72b2E9aDb0F60112B',
    name: 'SushiToken',
    symbol: 'SUSHI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/SUSHI/logo.svg',
  },
  {
    chainId: 1,
    address: '0x8947da500Eb47F82df21143D0C01A29862a8C3c5',
    name: 'Optimistic Thales Token',
    symbol: 'THALES',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/THALES/logo.png',
  },
  {
    chainId: 10,
    address: '0x217D47011b23BB961eB6D93cA9945B7501a5BB11',
    name: 'Optimistic Thales Token',
    symbol: 'THALES',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/THALES/logo.png',
  },
  {
    chainId: 1,
    address: '0xbb9bc244d798123fde783fcc1c72d3bb8c189413',
    name: 'TheDAO',
    symbol: 'TheDAO',
    decimals: 16,
    logoURI: 'https://ethereum-optimism.github.io/data/TheDAO/logo.svg',
  },
  {
    chainId: 10,
    address: '0xd8f365c2c85648f9b89d9f1bf72c0ae4b1c36cfd',
    name: 'TheDAO',
    symbol: 'TheDAO',
    decimals: 16,
    logoURI: 'https://ethereum-optimism.github.io/data/TheDAO/logo.svg',
  },
  {
    chainId: 42,
    address: '0x8274ea38fe9bea66f8e7c6f3ef742b85d86aeb5d',
    name: 'TheDAO',
    symbol: 'TheDAO',
    decimals: 16,
    logoURI: 'https://ethereum-optimism.github.io/data/TheDAO/logo.svg',
  },
  {
    chainId: 69,
    address: '0x35597dC6f8fDc81d71b311A9E4e2710ef6accb68',
    name: 'TheDAO',
    symbol: 'TheDAO',
    decimals: 16,
    logoURI: 'https://ethereum-optimism.github.io/data/TheDAO/logo.svg',
  },
  {
    chainId: 1,
    address: '0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0',
    name: 'Tellor',
    symbol: 'TRB',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/TRB/logo.png',
  },
  {
    chainId: 10,
    address: '0xaf8ca653fa2772d58f4368b0a71980e9e3ceb888',
    name: 'Tellor',
    symbol: 'TRB',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/TRB/logo.png',
  },
  {
    chainId: 42,
    address: '0x7B8AC044ebce66aCdF14197E8De38C1Cc802dB4A',
    name: 'Tellor',
    symbol: 'TRB',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/TRB/logo.png',
  },
  {
    chainId: 69,
    address: '0x5e70affe232e2919792f77eb94e566db0320fa88',
    name: 'Tellor',
    symbol: 'TRB',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/TRB/logo.png',
  },
  {
    chainId: 1,
    address: '0x0000000000085d4780B73119b644AE5ecd22b376',
    name: 'TrueUSD',
    symbol: 'TUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/TUSD/logo.png',
  },
  {
    chainId: 10,
    address: '0xcB59a0A753fDB7491d5F3D794316F1adE197B21E',
    name: 'TrueUSD',
    symbol: 'TUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/TUSD/logo.png',
  },
  {
    chainId: 42,
    address: '0x1794230aBe1995fcFe6B14865D4B6928121AB9d9',
    name: 'TrueUSD',
    symbol: 'TUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/TUSD/logo.png',
  },
  {
    chainId: 69,
    address: '0xFa956eB0c4b3E692aD5a6B2f08170aDE55999ACa',
    name: 'TrueUSD',
    symbol: 'TUSD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/TUSD/logo.png',
  },
  {
    chainId: 1,
    address: '0xDd1Ad9A21Ce722C151A836373baBe42c868cE9a4',
    name: 'Universal Basic Income',
    symbol: 'UBI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UBI/logo.svg',
  },
  {
    chainId: 10,
    address: '0xbb586ed34974b15049a876fd5366a4c2d1203115',
    name: 'Universal Basic Income',
    symbol: 'UBI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UBI/logo.svg',
  },
  {
    chainId: 42,
    address: '0x1ac5F168C220De2515Af6068c5A153aFe2c76d36',
    name: 'Universal Basic Income',
    symbol: 'UBI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UBI/logo.svg',
  },
  {
    chainId: 69,
    address: '0xbb586ed34974b15049a876fd5366a4c2d1203115',
    name: 'Universal Basic Income',
    symbol: 'UBI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UBI/logo.svg',
  },
  {
    chainId: 1,
    address: '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
    name: 'UMA Voting Token v1',
    symbol: 'UMA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UMA/logo.png',
  },
  {
    chainId: 10,
    address: '0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea',
    name: 'UMA Voting Token v1',
    symbol: 'UMA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UMA/logo.png',
  },
  {
    chainId: 1,
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UNI/logo.png',
  },
  {
    chainId: 10,
    address: '0x6fd9d7ad17242c41f7131d257212c54a0e816691',
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UNI/logo.png',
  },
  {
    chainId: 42,
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UNI/logo.png',
  },
  {
    chainId: 69,
    address: '0x5e31B81eaFba4b9371e77F34d6f3DA8091C3F2a0',
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/UNI/logo.png',
  },
  {
    chainId: 1,
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
  },
  {
    chainId: 10,
    address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
  },
  {
    chainId: 42,
    address: '0x50dC5200082d37d5dd34B4b0691f36e3632fE1A8',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
  },
  {
    chainId: 69,
    address: '0x4e62882864fB8CE54AFfcAf8D899A286762B011B',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
  },
  {
    chainId: 5,
    address: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
  },
  {
    chainId: 420,
    address: '0x7E07E15D2a87A24492740D16f5bdF58c16db0c4E',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
  },
  {
    chainId: 1,
    address: '0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6',
    name: 'Decentralized USD',
    symbol: 'USDD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/USDD/logo.png',
  },
  {
    chainId: 10,
    address: '0x7113370218f31764C1B6353BDF6004d86fF6B9cc',
    name: 'Decentralized USD',
    symbol: 'USDD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/USDD/logo.png',
  },
  {
    chainId: 42,
    address: '0xae73ddeafc00bc5ad03a96c2b268c3f5325c8d49',
    name: 'Decentralized USD',
    symbol: 'USDD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/USDD/logo.png',
  },
  {
    chainId: 69,
    address: '0x1f9bd96ddb4bd07d6061f8933e9ba9ede9967550',
    name: 'Decentralized USD',
    symbol: 'USDD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/USDD/logo.png',
  },
  {
    chainId: 10,
    address: '0x73cb180bf0521828d8849bc8CF2B920918e23032',
    name: 'USD+',
    symbol: 'USD+',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDplus/logo.svg',

  },
  {
    chainId: 1,
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDT/logo.png',
  },
  {
    chainId: 10,
    address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDT/logo.png',
  },
  {
    chainId: 42,
    address: '0xe0BB0D3DE8c10976511e5030cA403dBf4c25165B',
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDT/logo.png',
  },
  {
    chainId: 69,
    address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDT/logo.png',
  },
  {
    chainId: 5,
    address: '0xC2C527C0CACF457746Bd31B2a698Fe89de2b6d49',
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDT/logo.png',
  },
  {
    chainId: 420,
    address: '0x853eb4bA5D0Ba2B77a0A5329Fd2110d5CE149ECE',
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/USDT/logo.png',
  },
  {
    chainId: 1,
    address: '0xa693b19d2931d498c5b318df961919bb4aee87a5',
    name: 'UST (Wormhole)',
    symbol: 'UST',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/UST/logo.png',
  },
  {
    chainId: 10,
    address: '0xBA28feb4b6A6b81e3F26F08b83a19E715C4294fd',
    name: 'UST (Wormhole)',
    symbol: 'UST',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/UST/logo.png',
  },
  {
    chainId: 1,
    address: '0x0a5e677a6a24b2f1a2bf4f3bffc443231d2fdec8',
    name: 'dForce USD',
    symbol: 'USX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/USX/logo.svg',
  },
  {
    chainId: 10,
    address: '0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9',
    name: 'dForce USD',
    symbol: 'USX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/USX/logo.svg',
  },
  {
    chainId: 42,
    address: '0xf76ead4da04bbeb97d29f83e2ec3a621d0fb3c6e',
    name: 'dForce USD',
    symbol: 'USX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/USX/logo.svg',
  },
  {
    chainId: 69,
    address: '0xab7020476d814c52629ff2e4cebc7a8cdc04f18e',
    name: 'dForce USD',
    symbol: 'USX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/USX/logo.svg',
  },
  {
    chainId: 1,
    address: '0x27C4af9A860c4caDc358005F8b48140b2E434A7B',
    name: 'Validator',
    symbol: 'VALX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/VALX/logo.png',
  },
  {
    chainId: 10,
    address: '0x0A9AAa1c7542b42233AC7FfdA364E97adE21f160',
    name: 'Validator',
    symbol: 'VALX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/VALX/logo.png',
  },
  {
    chainId: 1,
    address: '0x6789D8a7a7871923Fc6430432A602879eCB6520a',
    name: 'veKwenta',
    symbol: 'veKWENTA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/veKWENTA/logo.svg',
  },
  {
    chainId: 10,
    address: '0x678d8f4Ba8DFE6bad51796351824DcCECeAefF2B',
    name: 'veKwenta',
    symbol: 'veKWENTA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/veKWENTA/logo.svg',
  },
  {
    chainId: 5,
    address: '0xf36c9a9E8333663F8CA3608C5582916628E79e3f',
    name: 'veKwenta',
    symbol: 'veKWENTA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/veKWENTA/logo.svg',
  },
  {
    chainId: 420,
    address: '0x3e52b5f840eafD79394c6359E93Bf3FfdAE89ee4',
    name: 'veKwenta',
    symbol: 'veKWENTA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/veKWENTA/logo.svg',
  },
  {
    chainId: 10,
    address: '0x3c8B650257cFb5f272f799F5e2b4e65093a11a05',
    name: 'Velodrome',
    symbol: 'VELO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/VELO/logo.svg',
  },
  {
    chainId: 1,
    address: '0x81f8f0bb1cB2A06649E51913A151F0E7Ef6FA321',
    name: 'VitaDAO Token',
    symbol: 'VITA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/VITA/logo.svg',
  },
  {
    chainId: 10,
    address: '0x7d14206c937e70e19e3a5b94011faf0d5b3928e2',
    name: 'VitaDAO Token',
    symbol: 'VITA',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/VITA/logo.svg',
  },
  {
    chainId: 1,
    address: '0x0B6F3c17e1626a7cBfA4302CE4E3c45522d23A83',
    name: 'WardenSwap',
    symbol: 'WAD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WAD/logo.png',
  },
  {
    chainId: 10,
    address: '0x703D57164CA270b0B330A87FD159CfEF1490c0a5',
    name: 'WardenSwap',
    symbol: 'WAD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WAD/logo.png',
  },
  {
    chainId: 42,
    address: '0x2C7A8122A1b512AdC848024cE72e837001EcB586',
    name: 'WardenSwap',
    symbol: 'WAD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WAD/logo.png',
  },
  {
    chainId: 69,
    address: '0xD1917629B3E6A72E6772Aab5dBe58Eb7FA3C2F33',
    name: 'WardenSwap',
    symbol: 'WAD',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WAD/logo.png',
  },
  {
    chainId: 1,
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
  },
  {
    chainId: 10,
    address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
  },
  {
    chainId: 42,
    address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
  },
  {
    chainId: 69,
    address: '0x2382a8f65b9120E554d1836a504808aC864E169d',
    name: 'Wrapped BTC L2',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
  },
  {
    chainId: 5,
    address: '0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05',
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
  },
  {
    chainId: 420,
    address: '0xe0a592353e81a94Db6E3226fD4A99F881751776a',
    name: 'Wrapped BTC L2',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
  },
  {
    chainId: 10,
    address: '0x4200000000000000000000000000000000000006',
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WETH/logo.png',

  },
  {
    chainId: 5,
    address: '0x753BD3E00B9585AC9a94Fdf287f41d8D51e5CF8b',
    name: 'WAMP',
    symbol: 'WMP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WMP/logo.svg',
  },
  {
    chainId: 420,
    address: '0x35D48A789904E9b15705977192e5d95e2aF7f1D3',
    name: 'WAMP',
    symbol: 'WMP',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WMP/logo.svg',
  },
  {
    chainId: 1,
    address: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
    name: 'Wootrade Network',
    symbol: 'WOO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WOO/logo.svg',
  },
  {
    chainId: 10,
    address: '0x871f2F2ff935FD1eD867842FF2a7bfD051A5E527',
    name: 'Wootrade Network',
    symbol: 'WOO',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/WOO/logo.svg',
  },
  {
    chainId: 1,
    address: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
    name: 'Wrapped liquid staked Ether 2.0',
    symbol: 'wstETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/wstETH/logo.svg',
  },
  {
    chainId: 10,
    address: '0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb',
    name: 'Wrapped liquid staked Ether 2.0',
    symbol: 'wstETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/wstETH/logo.svg',
  },
  {
    chainId: 42,
    address: '0xa88751C0a08623E11ff38c6B70F2BbEe7865C17c',
    name: 'Wrapped liquid staked Ether 2.0',
    symbol: 'wstETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/wstETH/logo.svg',
  },
  {
    chainId: 69,
    address: '0xF9C842dE4381a70eB265d10CF8D43DceFF5bA935',
    name: 'Wrapped liquid staked Ether 2.0',
    symbol: 'wstETH',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/wstETH/logo.svg',
  },
  {
    chainId: 1,
    address: '0xB4272071eCAdd69d933AdcD19cA99fe80664fc08',
    name: 'CryptoFranc',
    symbol: 'XCHF',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/XCHF/logo.png',
  },
  {
    chainId: 10,
    address: '0xE4F27b04cC7729901876B44f4EAA5102EC150265',
    name: 'CryptoFranc',
    symbol: 'XCHF',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/XCHF/logo.png',
  },
  {
    chainId: 1,
    address: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
    name: '0x Protocol Token',
    symbol: 'ZRX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ZRX/logo.png',
  },
  {
    chainId: 10,
    address: '0xD1917629B3E6A72E6772Aab5dBe58Eb7FA3C2F33',
    name: '0x Protocol Token',
    symbol: 'ZRX',
    decimals: 18,
    logoURI: 'https://ethereum-optimism.github.io/data/ZRX/logo.png',
  },
  {
    chainId: 1,
    address: '0xc56c2b7e71B54d38Aab6d52E94a04Cbfa8F604fA',
    name: 'Z.com USD',
    symbol: 'ZUSD',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/ZUSD/logo.svg',
  },
  {
    chainId: 10,
    address: '0x6e4cc0Ab2B4d2eDAfa6723cFA1582229F1Dd1Be1',
    name: 'Z.com USD',
    symbol: 'ZUSD',
    decimals: 6,
    logoURI: 'https://ethereum-optimism.github.io/data/ZUSD/logo.svg',
  },
  {
    chainId: 1,
    address: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
    name: 'Decentraland MANA',
    symbol: 'MANA',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png',
  },
  {
    chainId: 10,
    address: '0x899F737750db562b88c1E412eE1902980D3a4844',
    name: 'BPT Helter Skelter',
    symbol: 'BPT-WSTETH-USDC',
    decimals: 18,
    logoURI: 'https://optimistic.etherscan.io/images/main/empty-token.png',
  },
  {
    chainId: 10,
    address: '0xf30dB0Ca4605e5115Df91B56BD299564dcA02666',
    name: 'BPT Just BEET it',
    symbol: 'BPT-BEET-WSTEH',
    decimals: 18,
    logoURI: 'https://optimistic.etherscan.io/images/main/empty-token.png',
  },
  {
    symbol: 'GMX',
    name: 'GMX',
    address: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a.png',
  },
  {
    symbol: 'TLPT',
    name: 'tLPT',
    address: '0xfaC38532829fDD744373fdcd4708Ab90fA0c4078',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/26972/thumb/tenderLPT.png?1661152923',
  },
  {
    symbol: 'CARBON',
    name: 'Carboncoin',
    address: '0xfa42DA1bd08341537a44a4ca9D236D1c00A98b40',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1637832686338_7422730180325647.png',
  },
  {
    symbol: 'LINK',
    name: 'Chainlink',
    address: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
  },
  {
    symbol: 'CREAM',
    name: 'Cream',
    address: '0xf4D48Ce3ee1Ac3651998971541bAdbb9A14D7234',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cryptologos.cc/logos/cream-finance-cream-logo.png',
  },
  {
    symbol: 'RGT',
    name: 'Rari Governance',
    address: '0xef888bcA6AB6B1d26dbeC977C455388ecd794794',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/RariGovernanceToken_32.png',
  },
  {
    symbol: 'ACRV',
    name: 'Aladdin cvxCRV',
    address: '0xebf1F92D4384118bFb91B4496660a95931c92861',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/25395/thumb/Sv06cFHS_400x400.jpg?1651707422',
  },
  {
    symbol: 'AUC',
    name: 'Auctus',
    address: '0xea986d33eF8a20A96120ecc44dBdD49830192043',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/2165/thumb/Auc_Discord_Avatar1.png?1618983355',
  },
  {
    symbol: 'ELK',
    name: 'Elk',
    address: '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
  },
  {
    symbol: 'NYAN',
    name: 'ArbiNYAN',
    address: '0xeD3fB761414DA74b74F33e5c5a1f78104b188DfC',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1638170899569_12235125291982096.png',
  },
  {
    symbol: 'AWETH',
    name: 'Aave WETH',
    address: '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/17238/thumb/aWETH_2x.png?1626940782',
  },
  {
    symbol: 'ALTA',
    name: 'Alta Finance',
    address: '0xe0cCa86B254005889aC3a81e737f56a14f4A38F5',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18713/thumb/AFN-token-Altafin-200.png?1633079552',
  },
  {
    symbol: 'DEFI5',
    name: 'DEFI Top 5 Tokens Index',
    address: '0xdeBa25AF35e4097146d7629055E0EC3C71706324',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/13691/thumb/thGDKHo.png?1610959947',
  },
  {
    symbol: 'KSW',
    name: 'KillSwitch',
    address: '0xdc7179416c08c15f689d9214A3BeC2Dd003DeABc',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/20215/thumb/logo_%2824%29.png?1636670633',
  },
  {
    symbol: 'Z2O',
    name: 'ZeroTwOhm',
    address: '0xdb96f8efd6865644993505318cc08FF9C42fb9aC',
    decimals: 9,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1637928264168_1276740912855694.png',
  },
  {
    symbol: 'SWPR',
    name: 'Swapr',
    address: '0xdE903E2712288A1dA82942DDdF2c20529565aC30',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18740/thumb/swapr.jpg?1633516501',
  },
  {
    symbol: 'HONEY',
    name: 'Honey Pot BeeKeepers',
    address: '0xdE31e75182276738B0c025daa8F80020A4F2fbFE',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18380/thumb/ac6fTCfDQDIV.jpg?1631695015',
  },
  {
    symbol: 'SUSHI',
    name: 'Sushi',
    address: '0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
  },
  {
    symbol: 'DBL',
    name: 'Doubloon',
    address: '0xd3f1Da62CAFB7E7BC6531FF1ceF6F414291F03D3',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/23660/thumb/galleon-sky.png?1651744892',
  },
  {
    symbol: 'USX',
    name: 'dForce USD',
    address: '0xcd14C3A2ba27819B352aae73414A26e2b366dC50',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/dforceusd_32.png',
  },
  {
    symbol: 'NFTI',
    name: 'Scalara NFT Index',
    address: '0xcFe3FBc98D80f7Eca0bC76cD1F406A19dD425896',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xcfe3fbc98d80f7eca0bc76cd1f406a19dd425896.png',
  },
  {
    symbol: 'WOO',
    name: 'WOO Network',
    address: '0xcAFcD85D8ca7Ad1e1C6F82F651fA15E33AEfD07b',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367',
  },
  {
    symbol: 'deETH',
    name: 'deBridge Ether',
    address: '0xcAB86F6Fb6d1C2cBeeB97854A0C023446A075Fe3',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xcab86f6fb6d1c2cbeeb97854a0c023446a075fe3.png',
  },
  {
    symbol: 'NFD',
    name: 'Feisty Doge NFT',
    address: '0xc9c2B86CD4cdbAB70cd65D22EB044574c3539F6c',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/feistydoge_32.png',
  },
  {
    symbol: 'HOP',
    name: 'Hop Protocol',
    address: '0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc.png',
  },
  {
    symbol: 'CREDA',
    name: 'CreDA',
    address: '0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d/logo.png',
  },
  {
    symbol: 'RAI',
    name: 'Rai Reflex Index',
    address: '0xaeF5bbcbFa438519a5ea80B4c7181B4E78d419f2',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/raireflexindex_32.png',
  },
  {
    symbol: 'MATTER',
    name: 'AntiMatter',
    address: '0xaaA62D9584Cbe8e4D68A43ec91BfF4fF1fAdB202',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/antimatter_32.png',
  },
  {
    symbol: 'DF',
    name: 'dForce',
    address: '0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/9709/thumb/xlGxxIjI_400x400.jpg?1571006794',
  },
  {
    symbol: 'TNGL',
    name: 'Tangle',
    address: '0xa943F863fA69ff4F6D9022843Fb861BBEe45B2ce',
    decimals: 9,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18312/thumb/tangle.PNG?1631510159',
  },
  {
    symbol: 'VSTA',
    name: 'Vesta Finance',
    address: '0xa684cd057951541187f288294a1e1C2646aA2d24',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1648808309036_20667985804740718.jpg',
  },
  {
    symbol: 'LYS',
    name: 'LYS Capital',
    address: '0xa4f595Ba35161c9fFE3db8c03991B9C2CBB26C6b',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/20863/thumb/1637613107-lys-logo-cg-200pix.png?1637805637',
  },
  {
    symbol: 'GNO',
    name: 'Gnosis',
    address: '0xa0b862F60edEf4452F25B4160F177db44DeB6Cf1',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x6810e776880c02933d47db1b9fc05908e5386b96.png',
  },
  {
    symbol: 'USDT',
    name: 'Tether',
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    decimals: 6,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
  },
  {
    symbol: 'MGN',
    name: 'Mugen Finance',
    address: '0xFc77b86F3ADe71793E1EEc1E7944DB074922856e',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/27340/thumb/Group_2915.jpg?1663549948',
  },
  {
    symbol: 'UNI',
    name: 'Uniswap',
    address: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
    address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    decimals: 6,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
  },
  {
    symbol: 'MIM',
    name: 'Magic Internet Money',
    address: '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a.png',
  },
  {
    symbol: 'sSPELL',
    name: 'Staked Spell Token',
    address: '0xF7428FFCb2581A2804998eFbB036A43255c8A8D3',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/logos/main/network/arbitrum/0xF7428FFCb2581A2804998eFbB036A43255c8A8D3.jpg',
  },
  {
    symbol: 'HDX',
    name: 'Hydranet',
    address: '0xF4fe727C855c2D395852ca43F645caB4b504Af23',
    decimals: 9,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/25177/thumb/HDXdarkblueInv.png?1652694650',
  },
  {
    symbol: 'MNTO',
    name: 'Minato',
    address: '0xF0DFAD1817b5ba73726B02Ab34dd4B4B00bcD392',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664',
  },
  {
    symbol: 'DUSD',
    name: 'DigitalDollar',
    address: '0xF0B5cEeFc89684889e5F7e0A7775Bd100FcD3709',
    decimals: 6,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xF0B5cEeFc89684889e5F7e0A7775Bd100FcD3709/logo.png',
  },
  {
    symbol: 'XTK',
    name: 'xToken',
    address: '0xF0A5717Ec0883eE56438932b0fe4A20822735fBa',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14089/thumb/xToken.png?1614226407',
  },
  {
    symbol: 'O3',
    name: 'O3 Swap',
    address: '0xEe9801669C6138E84bD50dEB500827b776777d28',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xEe9801669C6138E84bD50dEB500827b776777d28/logo.png',
  },
  {
    symbol: 'WMEMO',
    name: 'Wonderful Memories',
    address: '0xECf2ADafF1De8A512f6e8bfe67a2C836EDb25Da3',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/22392/thumb/wMEMO.png?1665832927',
  },
  {
    symbol: 'FOREX',
    name: 'handle fi',
    address: '0xDb298285FE4C5410B05390cA80e8Fbe9DE1F259B',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18533/thumb/handle.fiFOREXLogoDark200x200.png?1632755675',
  },
  {
    symbol: 'DAI',
    name: 'Dai',
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
  },
  {
    symbol: 'USDS',
    name: 'Sperax USD',
    address: '0xD74f5255D557944cf7Dd0E45FF521520002D5748',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xd74f5255d557944cf7dd0e45ff521520002d5748.png',
  },
  {
    symbol: 'ICE',
    name: 'Ice Token',
    address: '0xCB58418Aa51Ba525aEF0FE474109C0354d844b7c',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/logos/main/network/arbitrum/0xCB58418Aa51Ba525aEF0FE474109C0354d844b7c.jpg',
  },
  {
    symbol: 'MYC',
    name: 'Mycelium',
    address: '0xC74fE4c715510Ec2F8C61d70D397B32043F55Abe',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xc74fe4c715510ec2f8c61d70d397b32043f55abe.png',
  },
  {
    symbol: 'OSEA',
    name: 'Omnisea',
    address: '0xC72633F995e98Ac3BB8a89e6a9C4Af335C3D6E44',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png?1658195766',
  },
  {
    symbol: 'DXD',
    name: 'DXdao',
    address: '0xC3Ae0333F0F34aa734D5493276223d95B8F9Cb37',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xa1d65e8fb6e87b60feccbc582f7f97804b725521.png',
  },
  {
    symbol: 'BADGER',
    name: 'Badger DAO',
    address: '0xBfa641051Ba0a0Ad1b0AcF549a89536A0D76472E',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x3472a5a71965499acd81997a54bba8d852c6e53d.png',
  },
  {
    symbol: 'FUSE',
    name: 'Fuse Token',
    address: '0xBDeF0E9ef12E689F366fe494A7A7D0dad25D9286',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/10347/thumb/vUXKHEe.png?1601523640',
  },
  {
    symbol: 'NDX',
    name: 'Indexed Finance',
    address: '0xB965029343D55189c25a7f3e0c9394DC0F5D41b1',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/13546/thumb/indexed-light.74bb5471.png?1609712728',
  },
  {
    symbol: 'BRC',
    name: 'Brinc Finance',
    address: '0xB5de3f06aF62D8428a8BF7b4400Ea42aD2E0bc53',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/26116/thumb/76469697.png?1655937532',
  },
  {
    symbol: 'IMO',
    name: 'Ideamarket',
    address: '0xB41bd4C99dA73510d9e081C5FADBE7A27Ac1F814',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xB41bd4C99dA73510d9e081C5FADBE7A27Ac1F814/logo.png',
  },
  {
    symbol: 'BUCK',
    name: 'Arbucks',
    address: '0xAFD871f684F21Ab9D7137608C71808f83D75e6fc',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xAFD871f684F21Ab9D7137608C71808f83D75e6fc/logo.png',
  },
  {
    symbol: 'DEGEN',
    name: 'DEGEN Index',
    address: '0xAE6e3540E97b0b9EA8797B157B510e133afb6282',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14143/thumb/alpha_logo.png?1614651244',
  },
  {
    symbol: 'SUSD',
    name: 'sUSD',
    address: '0xA970AF1a584579B618be4d69aD6F73459D112F95',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765',
  },
  {
    symbol: 'DVF',
    name: 'Rhino fi',
    address: '0xA7Aa2921618e3D63dA433829d448b58C9445A4c3',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/dvf_32.png',
  },
  {
    symbol: 'TCR',
    name: 'Tracer DAO',
    address: '0xA72159FC390f0E3C6D415e658264c7c4051E9b87',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18271/thumb/tracer_logo.png?1631176676',
  },
  {
    symbol: 'WCHI',
    name: 'Wrapped CHI',
    address: '0xA64eCCe74F8CdB7a940766B71f1b108BAC69851a',
    decimals: 8,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/2091/thumb/xaya_logo-1.png?1547036406',
  },
  {
    symbol: 'ARVAULT',
    name: 'ArVault',
    address: '0xA6219B4Bf4B861A2b1C02da43b2aF266186eDC04',
    decimals: 9,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xA6219B4Bf4B861A2b1C02da43b2aF266186eDC04/logo.png',
  },
  {
    symbol: 'ARBIS',
    name: 'Arbis Finance',
    address: '0x9f20de1fc9b161b34089cbEAE888168B44b03461',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x9f20de1fc9b161b34089cbEAE888168B44b03461/logo.png',
  },
  {
    symbol: 'XDO',
    name: 'xDollar',
    address: '0x9eF758aC000a354479e538B8b2f01b917b8e89e7',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1637831645816_6024340081244819.png',
  },
  {
    symbol: 'ASCEND',
    name: 'Ascension Protocol',
    address: '0x9e724698051DA34994F281bD81C3E7372d1960AE',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/16019/thumb/icon200.png?1622612188',
  },
  {
    symbol: 'FXS',
    name: 'Frax Share',
    address: '0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1648904710979_811680259074864.png',
  },
  {
    symbol: 'IMX',
    name: 'Impermax  OLD ',
    address: '0x9c67eE39e3C4954396b9142010653F17257dd39C',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/impermax_32.png',
  },
  {
    symbol: 'ALN',
    name: 'Aluna',
    address: '0x9b3fa2A7C3EB36d048A5d38d81E7fAFC6bc47B25',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14379/thumb/uaLoLU8c_400x400_%281%29.png?1627873106',
  },
  {
    symbol: 'MULTI',
    name: 'Multichain',
    address: '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238',
  },
  {
    symbol: 'MATH',
    name: 'MATH',
    address: '0x99F40b01BA9C469193B360f72740E416B17Ac332',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png',
  },
  {
    symbol: 'BIFI',
    name: 'Beefy Finance',
    address: '0x99C409E5f62E4bd2AC142f17caFb6810B8F0BAAE',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x99C409E5f62E4bd2AC142f17caFb6810B8F0BAAE/logo.png',
  },
  {
    symbol: 'VISR',
    name: 'Visor',
    address: '0x995C235521820f2637303Ca1970c7c044583df44',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14381/thumb/visor_logo.png?1615782828',
  },
  {
    symbol: 'EUX',
    name: 'dForce EUR',
    address: '0x969131D8ddC06C2Be11a13e6E7fACF22CF57d95e',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/dforceeur_32.png',
  },
  {
    symbol: 'PICKLE',
    name: 'Pickle Finance',
    address: '0x965772e0E9c84b6f359c8597C891108DcF1c5B1A',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/pickle_32.png',
  },
  {
    symbol: 'SWPR',
    name: 'Swapr',
    address: '0x955b9fe60a5b5093df9Dc4B1B18ec8e934e77162',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/icons/master/token/swpr.jpg',
  },
  {
    symbol: 'LIQD',
    name: 'Liquid Finance',
    address: '0x93C15cd7DE26f07265f0272E0b831C5D7fAb174f',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x93c15cd7de26f07265f0272e0b831c5d7fab174f.png',
  },
  {
    symbol: 'MAGNET',
    name: 'Magnethereum',
    address: '0x8eD4191F81F1e1D24a8a1195267D024d9358c9d7',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/Magnethereum/MyFiles/main/magnethereum.jpg',
  },
  {
    symbol: 'AMY',
    name: 'Amy Finance Token',
    address: '0x8Fbd420956FDD301f4493500fd0BCaAa80f2389C',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1637893005424_2740901520185888.png',
  },
  {
    symbol: 'NITRODOGE',
    name: 'nitroDOGE',
    address: '0x8E75DafEcf75de7747A05B0891177ba03333a166',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/27161/thumb/nitrodoge_2.png?1662280112',
  },
  {
    symbol: 'GOHM',
    name: 'Governance OHM',
    address: '0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png?1638764900',
  },
  {
    symbol: 'FLUID',
    name: 'FluidFi',
    address: '0x876Ec6bE52486Eeec06bc06434f3E629D695c6bA',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x876Ec6bE52486Eeec06bc06434f3E629D695c6bA/logo.png',
  },
  {
    symbol: 'OMIC',
    name: 'Omicron',
    address: '0x86b3353387F560295a8Fa7902679735E5f076Bd5',
    decimals: 9,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/19954/thumb/YHCCP1e.png?1636339997',
  },
  {
    symbol: 'ARBYS',
    name: 'Arbys',
    address: '0x86A1012d437BBFf84fbDF62569D12d4FD3396F8c',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x86A1012d437BBFf84fbDF62569D12d4FD3396F8c/logo.png',
  },
  {
    symbol: 'FXUSD',
    name: 'handleUSD',
    address: '0x8616E8EA83f048ab9A5eC513c9412Dd2993bcE3F',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/26954/thumb/fxUSDLogo_200px-200px.png?1660988882',
  },
  {
    symbol: 'AGVE',
    name: 'Agave',
    address: '0x848e0BA28B637e8490D88BaE51fA99C87116409B',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14146/thumb/agve.png?1614659384',
  },
  {
    symbol: 'YFI',
    name: 'yearn finance',
    address: '0x82e3A8F066a6989666b031d916c43672085b1582',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cryptologos.cc/logos/yearn-finance-yfi-logo.png',
  },
  {
    symbol: 'WETH',
    name: 'WETH',
    address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
  },
  {
    symbol: 'ADAI',
    name: 'Aave DAI',
    address: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14242/thumb/aDAI.84b6c41f.png?1615528749',
  },
  {
    symbol: 'CVOL',
    name: 'Crypto Volatility',
    address: '0x8096aD3107715747361acefE685943bFB427C722',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/24008/thumb/govi-dao.03ef3083.png?1646029661',
  },
  {
    symbol: 'DHT',
    name: 'dHEDGE DAO',
    address: '0x8038F3C971414FD1FC220bA727F2D4A0fC98cb65',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xca1207647ff814039530d7d35df0e1dd2e91fa84.png',
  },
  {
    symbol: '0XBTC',
    name: '0xBitcoin',
    address: '0x7cb16cb78ea464aD35c8a50ABF95dff3c9e09d5d',
    decimals: 8,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/4454/thumb/0xbtc.png?1561603765',
  },
  {
    symbol: 'SDT',
    name: 'Stake DAO',
    address: '0x7bA4a00d54A07461D9DB2aEF539e91409943AdC9',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/13724/thumb/stakedao_logo.jpg?1611195011',
  },
  {
    symbol: 'PPEGG',
    name: 'Parrot Egg',
    address: '0x78055dAA07035Aa5EBC3e5139C281Ce6312E1b22',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x78055dAA07035Aa5EBC3e5139C281Ce6312E1b22/logo.png',
  },
  {
    symbol: 'DEUSDC',
    name: 'deUSDC',
    address: '0x76b44e0Cf9bD024dbEd09E1785DF295D59770138',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/26669/thumb/deUSDC.png?1659509853',
  },
  {
    symbol: 'SDL',
    name: 'Saddle Finance',
    address: '0x75C9bC761d88f70156DAf83aa010E84680baF131',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x75c9bc761d88f70156daf83aa010e84680baf131.png',
  },
  {
    symbol: 'PERP',
    name: 'Perpetual',
    address: '0x753D224bCf9AAFaCD81558c32341416df61D3DAC',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771',
  },
  {
    symbol: 'BSGG',
    name: 'BETSWAP.GG',
    address: '0x750bfe8490175c2A9A9387b19Aa2AaE2d75dB638',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17169.png',
  },
  {
    symbol: 'wsOHM',
    name: 'Wrapped sOHM',
    address: '0x739ca6D71365a08f584c8FC4e1029045Fa8ABC4B',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x739ca6d71365a08f584c8fc4e1029045fa8abc4b.png',
  },
  {
    symbol: 'AUSDT',
    name: 'Aave USDT',
    address: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
    decimals: 6,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14243/thumb/aUSDT.78f5faae.png?1615528400',
  },
  {
    symbol: 'CNFI',
    name: 'Connect Financial',
    address: '0x6F5401c53e2769c858665621d22DDBF53D8d27c5',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/13592/thumb/cf-logo-iconic-black.png?1644479524',
  },
  {
    symbol: 'DPX',
    name: 'Dopex',
    address: '0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55.png',
  },
  {
    symbol: 'L2PAD',
    name: 'L2PAD',
    address: '0x6Ba4edd6dB54eD34d53D8d8883E599C4dba009fb',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/23694/thumb/l2pad_200x200.png?1645080419',
  },
  {
    symbol: 'DODO',
    name: 'DODO',
    address: '0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/dodo_32.png',
  },
  {
    symbol: 'POP',
    name: 'Popcorn',
    address: '0x68eAd55C258d6fa5e46D67fc90f53211Eab885BE',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/21438/thumb/pop-1_200_x_200.png?1662607611',
  },
  {
    symbol: 'STG',
    name: 'Stargate Finance',
    address: '0x6694340fc020c5E6B96567843da2df01b2CE1eb6',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x6694340fc020c5e6b96567843da2df01b2ce1eb6.png',
  },
  {
    symbol: 'VST',
    name: 'Vesta Stable',
    address: '0x64343594Ab9b56e99087BfA6F2335Db24c2d1F17',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x64343594ab9b56e99087bfa6f2335db24c2d1f17.png',
  },
  {
    symbol: 'USX',
    name: 'dForce USD',
    address: '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/17422/thumb/usx_32.png?1627600920',
  },
  {
    symbol: 'AUSDC',
    name: 'Aave USDC',
    address: '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
    decimals: 6,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14318/thumb/aUSDC.e260d492.png?1615527797',
  },
  {
    symbol: 'WSTETH',
    name: 'Wrapped stETH',
    address: '0x5979D7b546E38E414F7E9822514be443A4800529',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x5979d7b546e38e414f7e9822514be443a4800529.png',
  },
  {
    symbol: 'GMX',
    name: 'GMX',
    address: '0x590020B1005b8b25f1a2C82c5f743c540dcfa24d',
    decimals: 18,
    chainId: 42161,
    logoURI: null,
  },
  {
    symbol: 'QSD',
    name: 'QIAN Second Generation Dollar',
    address: '0x5837d843D49ffdefC78Fe09B5F371427c917946F',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/17985/thumb/QSD_.png?1630029729',
  },
  {
    symbol: 'KROM',
    name: 'Kromatika',
    address: '0x55fF62567f09906A85183b866dF84bf599a4bf70',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/20541/thumb/KROM_Transparent.png?1641398421',
  },
  {
    symbol: 'SPA',
    name: 'Sperax',
    address: '0x5575552988A3A80504bBaeB1311674fCFd40aD4B',
    decimals: 18,
    chainId: 42161,
    logoURI: null,
  },
  {
    symbol: 'OVR',
    name: 'OVR',
    address: '0x55704A0e9E2eb59E176C5b69655DbD3DCDCFc0F0',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png?1608518911',
  },
  {
    symbol: 'MAGIC',
    name: 'Magic',
    address: '0x539bdE0d7Dbd336b79148AA742883198BBF60342',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18623/small/Magic.png?1635755672',
  },
  {
    symbol: 'MTA',
    name: 'Meta',
    address: '0x5298Ee77A8f9E226898403eBAC33e68a62F770A0',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/11846/thumb/mStable.png?1594950533',
  },
  {
    symbol: 'PREMIA',
    name: 'Premia',
    address: '0x51fC0f6660482Ea73330E414eFd7808811a57Fa2',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70.png',
  },
  {
    symbol: 'PLS',
    name: 'PlutusDAO',
    address: '0x51318B7D00db7ACc4026C88c3952B66278B6A67F',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/25326/small/M6nUndNU_400x400.jpg?1651233987',
  },
  {
    symbol: 'MCB',
    name: 'MUX Protocol',
    address: '0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x4e352cf164e64adcbad318c3a1e222e9eba4ce42.png',
  },
  {
    symbol: 'NISHIB',
    name: 'NitroShiba',
    address: '0x4DAD357726b41bb8932764340ee9108cC5AD33a0',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/27288/thumb/nishib_logo.png?1663143249',
  },
  {
    symbol: 'TUSD',
    name: 'TrueUSD',
    address: '0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://images.prismic.io/tusd-homepage/fb4d581a-95ed-404c-b9de-7ab1365c1386_%E5%9B%BE%E5%B1%82+1.png',
  },
  {
    symbol: 'FST',
    name: 'Futureswap',
    address: '0x488cc08935458403a0458e45E20c0159c8AB2c92',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/futureswap2_32.png',
  },
  {
    symbol: 'LRC',
    name: 'Loopring',
    address: '0x46d0cE7de6247b0A95f67b43B589b4041BaE7fbE',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cryptologos.cc/logos/loopring-lrc-logo.png',
  },
  {
    symbol: 'ETHRISE',
    name: 'ETHRISE',
    address: '0x46D06cf8052eA6FdbF71736AF33eD23686eA1452',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/26761/thumb/ETHRISE.png?1660036893',
  },
  {
    symbol: 'DOG',
    name: 'The Doge NFT',
    address: '0x4425742F1EC8D98779690b5A3A6276Db85Ddc01A',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/thedogenft_32.png',
  },
  {
    symbol: 'EVO',
    name: 'EVO',
    address: '0x42006Ab57701251B580bDFc24778C43c9ff589A1',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135',
  },
  {
    symbol: 'CELR',
    name: 'Celer Network',
    address: '0x3a8B787f78D775AECFEEa15706D4221B40F345AB',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/4379/thumb/Celr.png?1554705437',
  },
  {
    symbol: 'MIMATIC',
    name: 'MAI',
    address: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
  },
  {
    symbol: 'SPELL',
    name: 'Spell',
    address: '0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af.png',
  },
  {
    symbol: 'LAND',
    name: 'Land',
    address: '0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x9d986a3f147212327dd658f712d5264a73a1fdb0.png',
  },
  {
    symbol: 'ROOBEE',
    name: 'Roobee',
    address: '0x3BD2dFd03BC7c3011ed7fb8c4d0949B382726cee',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629',
  },
  {
    symbol: 'PHONON',
    name: 'Phonon DAO',
    address: '0x39A49bc5017Fc668299Cd32e734C9269aCc35295',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/22308/thumb/ezgif-2-e7fb84364d.png?1641449852',
  },
  {
    symbol: 'ZYX',
    name: 'ZYX',
    address: '0x377c6E37633e390aEf9AFB4F5E0B16689351EeD4',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/11964/thumb/zyx.png?1596454904',
  },
  {
    symbol: 'PANA',
    name: 'PANA DAO',
    address: '0x369eB8197062093a20402935D3a707b4aE414E9D',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/27031/thumb/pana_logo.png?1661495937',
  },
  {
    symbol: 'PL2',
    name: 'Plenny',
    address: '0x3642c0680329ae3e103E2B5AB29DDfed4d43CBE5',
    decimals: 18,
    chainId: 42161,
    logoURI: null,
  },
  {
    symbol: 'COMP',
    name: 'Compound',
    address: '0x354A6dA3fcde098F8389cad84b0182725c6C91dE',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
  },
  {
    symbol: 'XUSD',
    name: 'xDollar Stablecoin',
    address: '0x3509f19581aFEDEff07c53592bc0Ca84e4855475',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1637892796159_3482286577377456.png',
  },
  {
    symbol: 'RDPX',
    name: 'Dopex Rebate',
    address: '0x32Eb7902D4134bf98A28b963D26de779AF92A212',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/16659/small/rDPX_200x200_Coingecko.png?1624614475',
  },
  {
    symbol: 'STRP',
    name: 'Strips Finance',
    address: '0x326c33FD1113c1F29B35B4407F3d6312a8518431',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://etherscan.io/token/images/strips_32.png',
  },
  {
    symbol: 'CTSI',
    name: 'Cartesi',
    address: '0x319f865b287fCC10b30d8cE6144e8b6D1b476999',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://elk.finance/tokens/logos/arbitrum/0x319f865b287fCC10b30d8cE6144e8b6D1b476999/logo.png',
  },
  {
    symbol: 'WBTC',
    name: 'Wrapped Bitcoin',
    address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    decimals: 8,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
  },
  {
    symbol: 'MKR',
    name: 'Maker',
    address: '0x2e9a6Df78E42a30712c10a9Dc4b1C8656f8F2879',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.png',
  },
  {
    symbol: 'MAGIC',
    name: 'MagicLand',
    address: '0x2c852D3334188BE136bFC540EF2bB8C37b590BAD',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18844/thumb/logo_-_2021-10-07T141625.399.png?1633587397',
  },
  {
    symbol: 'L2DAO',
    name: 'Layer2DAO',
    address: '0x2CaB3abfC1670D1a452dF502e216a66883cDf079',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/23699/thumb/Khp7Y4Sn.png?1645081048',
  },
  {
    symbol: 'STBZ',
    name: 'Stabilize',
    address: '0x2C110867CA90e43D372C1C2E92990B00EA32818b',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/12753/thumb/icon.png?1608771101',
  },
  {
    symbol: 'GRT',
    name: 'The Graph',
    address: '0x23A941036Ae778Ac51Ab04CEa08Ed6e2FE103614',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png',
  },
  {
    symbol: 'FLUX',
    name: 'Flux Protocol',
    address: '0x2338a5d62E9A766289934e8d2e83a443e8065b83',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cryptologos.cc/logos/zel-flux-logo.png',
  },
  {
    symbol: 'DERI',
    name: 'Deri Protocol',
    address: '0x21E60EE73F17AC0A411ae5D690f908c3ED66Fe12',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/13931/thumb/200vs200.jpg?1627649443',
  },
  {
    symbol: 'deUSDC',
    name: 'deBridge USD Coin',
    address: '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
    decimals: 6,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',
  },
  {
    symbol: 'aMoon',
    name: 'ArbiMoon',
    address: '0x1a7BD9EDC36Fb2b3c0852bcD7438c2A957Fd7Ad5',
    decimals: 9,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/ArbiMoonXyz/MyFiles/main/arbimoon.jpg',
  },
  {
    symbol: 'NIFLOKI',
    name: 'NitroFloki',
    address: '0x1FAe2A29940015632f2a6CE006dFA7E3225515A7',
    decimals: 9,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/28038/thumb/nifloki.png?1667198797',
  },
  {
    symbol: 'BFR',
    name: 'Buffer Token',
    address: '0x1A5B0aaF478bf1FDA7b934c76E7692D722982a6D',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1668519619894_7648242062364901.jpg',
  },
  {
    symbol: 'ALINK',
    name: 'Aave LINK',
    address: '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14315/thumb/aLINK.412c6589.png?1615527827',
  },
  {
    symbol: 'FRAX',
    name: 'Frax',
    address: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x17fc002b466eec40dae837fc4be5c67993ddbd6f.png',
  },
  {
    symbol: 'UMAMI',
    name: 'Umami',
    address: '0x1622bF67e6e5747b81866fE0b85178a93C7F86e3',
    decimals: 9,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x1622bF67e6e5747b81866fE0b85178a93C7F86e3/logo.png',
  },
  {
    symbol: 'ADOGE',
    name: 'ArbiDoge',
    address: '0x155f0DD04424939368972f4e1838687d6a831151',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/icons/master/token/doge.jpg',
  },
  {
    symbol: 'EMAX',
    name: 'EthereumMax',
    address: '0x123389C2f0e9194d9bA98c21E63c375B67614108',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x123389c2f0e9194d9ba98c21e63c375b67614108.png',
  },
  {
    symbol: 'CRV',
    name: 'Curve DAO',
    address: '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
  },
  {
    symbol: 'PBTC',
    name: 'pTokens BTC',
    address: '0x115D8bF0a53e751f8A472F88D587944EC1C8CA6D',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/25861/thumb/wMTpRljt_400x400.png?1654228097',
  },
  {
    symbol: 'JONES',
    name: 'Jones DAO',
    address: '0x10393c20975cF177a3513071bC110f7962CD67da',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/23290/small/3c8c2ed8-afb3-4b67-9937-5493acd88b50.jpg?1648597625',
  },
  {
    symbol: 'HND',
    name: 'Hundred Finance',
    address: '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1637892347363_6124669428880605.png',
  },
  {
    symbol: 'ALCH',
    name: 'Alchemy',
    address: '0x0e15258734300290a651FdBAe8dEb039a8E7a2FA',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14719/thumb/sbEW5W8.png?1617939648',
  },
  {
    symbol: 'ZIPT',
    name: 'Zippie',
    address: '0x0F61B24272AF65EACF6adFe507028957698e032F',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xedd7c94fd7b4971b916d15067bc454b9e1bad980.png',
  },
  {
    symbol: 'BOND',
    name: 'BarnBridge Governance Token',
    address: '0x0D81E50bC677fa67341c44D7eaA9228DEE64A4e1',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cryptologos.cc/logos/barnbridge-bond-logo.png',
  },
  {
    symbol: 'RDNT',
    name: 'Radiant Capital',
    address: '0x0C4681e6C0235179ec3D4F4fc4DF3d14FDD96017',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017.png',
  },
  {
    symbol: 'IUSD',
    name: 'iZUMi Bond USD',
    address: '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/25388/thumb/iusd-logo-symbol-10k%E5%A4%A7%E5%B0%8F.png?1651660620',
  },
  {
    symbol: 'ARBY',
    name: 'Adamant Token',
    address: '0x09ad12552ec45f82bE90B38dFE7b06332A680864',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x09ad12552ec45f82bE90B38dFE7b06332A680864/logo.png',
  },
  {
    symbol: 'SYN',
    name: 'Synapse',
    address: '0x080F6AEd32Fc474DD5717105Dba5ea57268F46eb',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png?1663921797',
  },
  {
    symbol: 'GOVI',
    name: 'GOVI',
    address: '0x07E49d5dE43DDA6162Fa28D24d5935C151875283',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/13875/thumb/GOVI.png?1612451531',
  },
  {
    symbol: 'QSD',
    name: ' QIAN second generation dollar',
    address: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://cloudstorage.openocean.finance/images/1637832211908_8528681646600584.png',
  },
  {
    symbol: 'AWBTC',
    name: 'Aave WBTC',
    address: '0x078f358208685046a11C85e8ad32895DED33A249',
    decimals: 8,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/14244/thumb/aWBTC.41453c2a.png?1615528721',
  },
  {
    symbol: 'KUN',
    name: 'Chemix Ecology Governance',
    address: '0x04cb2d263a7489f02d813eaaB9Ba1bb8466347F2',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/13177/thumb/kun_logo.png?1605923919',
  },
  {
    symbol: 'BAL',
    name: 'Balancer',
    address: '0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xba100000625a3754423978a60c9317c58a424e3d.png',
  },
  {
    symbol: 'AGVE',
    name: 'Agave',
    address: '0x03b95f1C84Af0607afd5dD87ca1FDE7572aa827F',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://elk.finance/tokens/logos/arbitrum/0x03b95f1C84Af0607afd5dD87ca1FDE7572aa827F/logo.png',
  },
  {
    symbol: 'CAP',
    name: 'Cap',
    address: '0x031d35296154279DC1984dCD93E392b1f946737b',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://assets.coingecko.com/coins/images/11775/thumb/CAP.png?1594083244',
  },
  {
    symbol: 'ETH',
    name: 'Ethereum',
    address: '0x0000000000000000000000000000000000000000',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
  },
  {
    symbol: 'GRAIN',
    name: 'Granary Token',
    address: '0x80bB30D62a16e1F2084dEAE84dc293531c3AC3A1',
    decimals: 18,
    chainId: 42161,
    logoURI: 'https://arbiscan.io/images/main/empty-token.png?v=1',
  },
  {
    chainId: 56,
    address: '0x0000000000000000000000000000000000000000',
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18
  },
  {
    chainId: 42161,
    address: '0x0000000000000000000000000000000000000000',
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18
  },
  {
    chainId: 43114,
    address: '0x0000000000000000000000000000000000000000',
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18
  }
]
