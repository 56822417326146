import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from 'styled-components'
import { Routes, Route } from 'react-router-dom'
import AppLanding from './pages/AppLanding'
import List from './pages/List'
import EnvironmentNotFound from './pages/EnvironmentNotFound'
import ForwardV2 from './pages/ForwardV2'
import Activity from './pages/Activity'
import Reports from './pages/Reports'
import Settings from './pages/Settings'
import Overview from './pages/Overview'
import Dashboard from './structure/Dashboard'
import theme from './styles/theme.js'
import { isDevelopment } from './constants/enviroment'
import { SubgraphsProvider } from './context/SubgraphsContext'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

function App() {
  const queryClient = new QueryClient()

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SubgraphsProvider>
          <Routes>
            <Route
              name='overview'
              path="/:id/overview"
              element={
                <Dashboard>
                  <Overview />
                </Dashboard>
              }
            />
            <Route
              name="settings"
              path="/:id/reports"
              element={
                <Dashboard>
                  <Reports />
                </Dashboard>
              }
            />
            <Route
              name="settings"
              path="/:id/settings"
              element={
                <Dashboard>
                  <Settings />
                </Dashboard>
              }
            />
            <Route
              name="settings"
              path="/:id/settings/:action"
              element={
                <Dashboard>
                  <Settings />
                </Dashboard>
              }
            />
            <Route
              name="settings"
              path="/:id/tasks"
              element={
                <Dashboard>
                  <Settings />
                </Dashboard>
              }
            />
            <Route
              name="activity"
              path="/:id/activity"
              element={
                <Dashboard>
                  <Activity />
                </Dashboard>
              }
            />
            <Route name="list" path="/list" element={<List />} />
            <Route
              name="v2"
              path="/smart-vaults/:url"
              element={<ForwardV2 />}
            />
            <Route
              name="environmentNotFound"
              path="/environment-not-found"
              element={<EnvironmentNotFound />}
            />
            <Route path="/" element={<AppLanding />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
          {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
        </SubgraphsProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
