import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext  from '../context/SubgraphsContext'


const useEnvironment = id => {
  const subgraphs = useContext(SubgraphsContext)
  return useQuery(['useEnvironments'], () => fetchAllTasks(id, subgraphs))
}

const fetchEnvironment = async (chainId, id, subgraphs) => {
  let environment = await request(
    subgraphs[chainId],
    gql`
    query Environment {
      environment(id: ${'"' + id.toLowerCase() + '"'}) {
        tasks {
          id
          name
          permissions
        }
      }
    }
    `
  )

  return environment?.environment?.tasks
}

const fetchAllTasks = async (id, subgraphs) => {
  let allTasks = []
  await Promise.all(Object.keys(subgraphs).map(async (chainId) => {
    const configs = await fetchEnvironment(chainId, id, subgraphs)
    if (configs) {
      configs.forEach(obj => {
        if (!allTasks.some(item => item.id === obj.id)) {
          allTasks.push(obj)
        }
      })
    }
  }))

  return allTasks
}

export default useEnvironment
