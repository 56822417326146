import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Footer from '../sections/AppLandingFooter'
import AppLandingCards from '../sections/AppLandingCards'
import TrustedBy from '../sections/TrustedBy'
import SearchBar from '../sections/SearchBar'
import Spotlight from '../sections/Spotlight'
import bgDef from '../assets/bg-def.svg'
import logo from '../assets/logo.svg'
import { Container, Heading3 } from '../styles/texts'

const Home = () => {
  return (
    <div>
      <Navbar />
      <div style={{ minHeight: 'calc(100vh - 490px)', background: '#12141A' }}>
        <HomeSection background={bgDef}>
          <Container>
            <TitleContainer>
              <Title className="violet">Browse</Title>
              <Title>enviroments</Title>
            </TitleContainer>
            <Searchbox>
              <SearchBar />
            </Searchbox>
            <TrustedBy />
          </Container>
        </HomeSection>
        <HomeSection>
          <Container>
            <Heading3 style={{ marginTop: '100px', textAlign: 'left' }}>
              FEATURED ENVIRONMENTS
            </Heading3>
            <AppLandingCards />
            <Heading3 style={{ marginTop: '100px', textAlign: 'left' }}>
              Spotlight
            </Heading3>
            <Spotlight />
          </Container>
        </HomeSection>
      </div>
      <Footer />
    </div>
  )
}

const Navbar = props => {
  return (
    <NavbarSection>
      <NavbarContainer>
        <Link to="/">
          <img alt="logo" src={logo} />
        </Link>
        <NavbarLink>
          Learn more in{' '}
          <a target="_blank" href="https://mimic.fi" rel="noreferrer">
            Mimic.fi
          </a>
        </NavbarLink>
      </NavbarContainer>
    </NavbarSection>
  )
}

const NavbarSection = styled.section`
  z-index: 100;
  width: 100%;
  margin: auto;
  position: sticky;
  background: #12141a;
`

const NavbarContainer = styled.div`
  margin: 0 48px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding 0;
`

const Text = styled.p`
  color: white;
  text-align: ${props => (props.left === 'true' ? 'left' : 'center')};

  font-family: 'DMSans';
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.75px;
`
const NavbarLink = styled(Text)`
  color: #a5a1b7;
  font-feature-settings: 'clig' off, 'liga' off;
  a {
    font-weight: 700;
    color: #6f5ce6;
    font-family: 'DMSansBold';
  }
`

const Title = styled.h1`
  color: white;
  font-family: 'VisbyBold';
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding: 0 10px;
  margin: 0;
  &.violet {
    color: #6f5ce6;
  }
`

const TitleContainer = styled.div`
  margin: 50px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 90vw;
`

const HomeSection = styled.section`
  background: url(${props => props.background}) no-repeat top left;
  background-size: cover;

  height: 100%;
  padding: 100px 0 0 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media only screen and (max-width: 700px) {
    padding: 0;
  }
  h2 {
    text-align: center;
  }
`

const Searchbox = styled.div`
  max-width: 900px;
  /* background: #6f5ce6; */
  margin: auto;
  /* margin-bottom: -90px; */
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 45px 0;
  h2 {
    color: white;
    font-family: 'VisbyBold';
    font-size: 19px;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.48px;
    margin: 0;
    text-align: left;
  }
  form {
    margin: 10px 0;
  }
`

export default Home
