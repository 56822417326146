import React, { useContext } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext  from '../context/SubgraphsContext'
import { formatTokenAmount, percent } from '../utils/math-utils'
import { compareAddresses } from '../utils/web3-utils'
import { RELAYER } from '../constants/playerAddress'

const useSmartVaultConfig = id => {
  const subgraphs = useContext(SubgraphsContext)
  const data = useQuery(['useSmartVaults', id], () =>
    fetchAllSmartVaultConfigs(id, subgraphs),
    {
      enabled: !!subgraphs && Object.keys(subgraphs).length > 0,
    }
  )

  return data
}

const fetchSmartVaultConfig = async (chainId, id, url) => {

  let smartVault = await request(
    url,
    gql`
    query SmartVault {
      environment(id: ${'"' + id.toLowerCase() + '"'}) {
        network
        smartVaults {
          id
           priceOracle {
             feeds {
               feed
               id
               quote {
                 decimals
                 id
                 name
                 symbol
               }
               base {
                 decimals
                 id
                 name
                 symbol
               }
             }
             name
             signers {
               signer
             }
           }
           registry
           relayerConfigs {
             balance
             feeCollector
             maxQuota
             quotaUsed
             relayer {
              feeCollector
              id
             }
             nativeToken {
               decimals
               id
               name
               symbol
             }
           }
           smartVaultFee {
             maxFeePercentage
             feePercentage
             feeCollector
             feeController {
              feeCollector
             }
           }
        }
      }
    }
    `
  )

  if (smartVault && smartVault.environment) {
    const { network } = smartVault.environment
    const sv = smartVault.environment.smartVaults[0]
    if (sv) {
      const signers = sv?.priceOracle?.signers?.map(obj => ({
        network: network,
        ...obj,
      }))

      const feeds = sv?.priceOracle?.feeds?.map(obj => ({
        network: network,
        ...obj,
      }))

      const relayerConfigs = sv?.relayerConfigs || []
      const lastRelayerConfig = relayerConfigs.find(item => compareAddresses(item.relayer.id, RELAYER))
      const transformedResponse = {
        address: {
          network: network,
          address: sv?.id,
        },
        priceOracleSigners: signers,
        priceOracleFeeds: feeds,
        registry: {
          network,
          address: sv?.registry,
        },
        relayerConfig: {
          network: network,
          balance: lastRelayerConfig?.balance
            ? formatTokenAmount(
                lastRelayerConfig?.balance,
                lastRelayerConfig?.nativeToken.decimals,
                { digits: 4, symbol: lastRelayerConfig.nativeToken.symbol }
              )
            : '',
          maxQuota: lastRelayerConfig?.maxQuota
            ? formatTokenAmount(
                lastRelayerConfig.maxQuota,
                lastRelayerConfig.nativeToken.decimals,
                { digits: 4, symbol: lastRelayerConfig.nativeToken.symbol }
              )
            : '',
          quotaUsed: lastRelayerConfig?.quotaUsed
            ? formatTokenAmount(
                lastRelayerConfig.quotaUsed,
                lastRelayerConfig.nativeToken.decimals,
                { digits: 4, symbol: lastRelayerConfig.nativeToken.symbol }
              )
            : '',
          feeCollector:
              lastRelayerConfig?.feeCollector &&
              lastRelayerConfig?.feeCollector !== '0x0000000000000000000000000000000000000000'
                ? lastRelayerConfig.feeCollector
                : lastRelayerConfig?.relayer?.feeCollector,
        },
        smartVaultFee: {
          network,
          feeCollector:  sv?.smartVaultFee?.feeCollector &&
            sv?.smartVaultFee?.feeCollector !== '0x0000000000000000000000000000000000000000'
              ? sv?.smartVaultFee?.feeCollector
              : sv?.smartVaultFee?.feeController?.feeCollector,
          feePercentage: sv?.smartVaultFee?.feePercentage
            ? percent(
                formatTokenAmount(sv?.smartVaultFee?.feePercentage, 18, {
                  digits: 4,
                })
              )
            : '-',
          maxFeePercentage: sv?.smartVaultFee?.maxFeePercentage
            ? percent(
                formatTokenAmount(sv?.smartVaultFee?.maxFeePercentage, 18, {
                  digits: 4,
                })
              )
            : '-',
        },
      }
      return transformedResponse
    } else {
      return null
    }
  }

  return null
}

const fetchAllSmartVaultConfigs = async (id, subgraphs) => {
  let allConfigs = {
    'Smart Vault': [],
    Relayer: [],
    'Fee': [],
    'Price Oracle': [],
    'Price Oracle Feeds': [],
    Registry: [],
  }
  const promises = Object.entries(subgraphs).map(([chainId, url]) =>
    fetchSmartVaultConfig(chainId, id, url)
  )

  const results = await Promise.allSettled(promises)

  results.forEach(result => {
    if (result.status === 'fulfilled' && result.value) {
      const configs = result.value
      allConfigs['Price Oracle'].push(...configs.priceOracleSigners)
      allConfigs['Price Oracle Feeds'].push(...configs.priceOracleFeeds)
      allConfigs['Registry'].push(configs.registry)
      allConfigs['Smart Vault'].push(configs.address)
      allConfigs['Relayer'].push(configs.relayerConfig)
      allConfigs['Fee'].push(configs.smartVaultFee)
    } else if (result.status === 'rejected') {
      console.error('Error fetching config:', result.reason)
    }
  })

  return allConfigs
}






export default useSmartVaultConfig
