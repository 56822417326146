import React from 'react'

/* eslint-disable no-unused-vars */
import { Chart, Bar } from 'react-chartjs-2'
import { BarController } from 'chart.js/auto' // Importa BarController
import useEnvironmentTasks from '../hooks/useEnvironmentTasks'
import { parseActionName } from '../utils/general-utils'

const colors = {
  purple: {
    default: 'rgba(111, 92, 230, 1)',
    half: 'rgba(111, 92, 230, 0.5)',
    quarter: 'rgba(111, 92, 230, 0.25)',
    zero: 'rgba(111, 92, 230, 0)',
  },
}

const options = {
  plugins: {
    legend: {
      labels: {
        color: '#A7ACC1'
      }
    },
    title: {
      color: '#A7ACC1'
    },
    tooltip: {
      backgroundColor: '#6F5CE6',
      bodyFontColor: '#fff',
      titleFontColor: '#fff',
      titleFont: {
        weight: 'bold',
        size: 18,
      },
      bodyFont: {
        size: 15,
        weight: 'normal',
      },
      bodySpacing: 13,
      cornerRadius: 8,
      padding: 15,
      caretPadding: 17,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      stacked: false,
      ticks: {
        color: '#A7ACC1',
      },
    },
    y: {
      beginAtZero: true,
      type: 'linear',
      ticks: {
        color: 'transparent',
      },
      callback: function(value, index, values) {
        return '$' + value
      }
    },
    'amounts-y-axis': {
      position: 'left',
      title: {
        display: true,
        text: 'Amounts',
        color: '#A7ACC1',
      },
      ticks: {
        color: '#A7ACC1',
        callback: function(value, index, values) {
          return '$ ' + value
        }
      },
    },
    'tasks-y-axis': {
      position: 'right',
      title: {
        display: true,
        text: 'Tasks Completed',
        color: '#A7ACC1',
      },
      ticks: {
        color: '#A7ACC1',
      },
    },
  },
  minBarThickness: 7,
  maxBarThickness: 15,
}



const CombinedChart = ({ data, envId}) => {
  const tasksNames = useEnvironmentTasks(envId)

  const barColors = ['#2E90FA', '#2EC9FA', '#46EEBC', '#12B76A', '#82B712', '#F0F429', '#F79009', '#FB6514', '#F04438', '#F0386F', '#F63D68', '#EE46BC', '#AE46EE', '#55efc4', '#e84393', '#fdcb6e', '#e17055', '#00b894', '#d63031', '#0984e3', '#b2bec3', '#fd79a8', '#74b9ff']
  const lineData = {}

  for (const [key, value] of Object.entries(data)) {
    for (const [date, { count }] of Object.entries(value)) {
      if (lineData[date]) {
        lineData[date] += count
      } else {
        lineData[date] = count
      }
    }
  }

  function getNameById(id) {
    if(tasksNames.data) {
      for (const obj of tasksNames.data) {
        if (obj.id === id) {
          return  parseActionName(obj.name)
        }
      }
    }
    return id
  }

  const processData = () => {
    let allDateLabels = []
    Object.values(data).forEach((dates) => {
      const currentDates = Object.keys(dates)
      allDateLabels = Array.from(new Set([...allDateLabels, ...currentDates]))
    })

    const relevantKeys = Object.keys(data).filter(key => {
      const name = getNameById(key).toLowerCase()
      return name.includes('swapper') || name.includes('depositor')
    })

    allDateLabels.sort()


    const datasets = Object.keys(data).map((key, index) => {
      const totalUSDByDate = allDateLabels.map((date) => {
        const dateData = data[key][date]
        return dateData ? dateData.totalUsd : 0
      })

      const colorIndex = index % barColors.length
      const backgroundColor = barColors[colorIndex]

      return {
        type: 'bar',
        label: getNameById(key),
        data: totalUSDByDate,
        backgroundColor: backgroundColor,
        yAxisID: 'amounts-y-axis',
        hidden: !relevantKeys.includes(key),
      }
    })

    return { dateLabels: allDateLabels, datasets }
  }

  const { dateLabels, datasets } = processData()


  const chartData = {
    labels: dateLabels,
    datasets: [
      ...datasets,
      {
        type: 'line',
        fill: true,
        label: 'Tasks Completed',
        yAxisID: 'tasks-y-axis',
        data: Object.values(lineData),
        borderColor: colors.purple.default,
        backgroundColor: colors.purple.half,
        pointBackgroundColor: colors.purple.default,
        lineTension: 0.8,
        pointRadius: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#6f5ce6',
        pointHoverBorderColor: '#6f5ce6',
      },
    ],
  }
  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default CombinedChart
