import {
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'

const initialContext = {
  version: '0.0.1' // TODO: manage app version for future updates
}

const StateContext = createContext(initialContext)
const DispatchContext = createContext(undefined)

export const AppProvider = ({ children }) => {
  const [storage, setStorage] = useLocalStorage('appManager')

  const [state, dispatch] = useReducer(
    storage ? storage : initialContext
  )

  useEffect(() => {
    setStorage({ ...initialContext })
  }, [state]); // eslint-disable-line

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useAppState = () => {
  return useContext(StateContext)
}

export const useAppDispatch = () => {
  const dispatch = useContext(DispatchContext)

  if (dispatch === undefined) {
    throw new Error('useAppDispatch must be used within a Provider')
  }
}
