import React from 'react'
import styled from 'styled-components'

function Table({ header, children, ...props }) {
  return (
    <StyledTable {...props}>
      {header && <thead>{header}</thead>}
      <tbody>{children}</tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 8px;

  td {
    border-top: 1px solid #2C3041;
  }

  td:first-child, th:first-child {
    padding-left: 0;
  }
  td:last-child, th:last-child {
    padding-right: 0;
  }
`

export default Table
