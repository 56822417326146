import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { v2url } from '../constants/v2url'

const ForwardV2 = () => {
  const params = useParams()
  useEffect(() => {
    // Redirigir a un enlace externo al inicializar el componente
    window.location.href = v2url + '/smart-vaults/' + params.url
  }, [params.url])

  return <></>
}

export default ForwardV2
