import React from 'react'
import styled from 'styled-components'
import { formatTokenAmount } from '../utils/math-utils'
import { tokens } from '../constants/tokens'

const ActivityDetail = ({ movements }) => {
  return (
    <Container>
      {movements && movements.map((movement, index) => {
        const token = tokens.find(
          element => element?.symbol === movement?.token?.symbol
        )
        return (
          <Movement key={index} added={movement.added.toString()}>
            {movement.added ? '+' : '-'}{' '}
            {formatTokenAmount(movement.amount, movement.token.decimals, {
              digits: 4,
            })}{' '}
            {movement.token.symbol}
            {token && <img src={token.logoURI} alt="Token" />}{' '}
          </Movement>
        )
      })}
    </Container>
  )
}


const Movement = styled.span`
    word-break: break-all;
`

const Container = styled.div`
  align-items: center;
  gap: 5px;
 img {
      height: 20px;
      border-radius: 100%;
      object-fit: scale-down;
      margin: 0px 5px -4px 4px;
 }
`

export default ActivityDetail
