import React from 'react'
import styled from 'styled-components'


function TableCell({ children, align, lite, ...props }) {
  return (
    <Td {...props} align={align} lite={lite}>
      <Content align={align} >
        {children}
      </Content>
    </Td>
  )
}

const Td = styled.td`
  padding: 10px 5px;
  color: ${props => props.theme.textWhite};
  width: ${props => (props.lite ? '200px' : 'none')};

  vertical-align: middle;
  font-size: 15px;
  text-align: ${props => props.align};
  @media only screen and (max-width: 700px) {
    padding: 20px 10px;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.align === 'center' ? 'center' : 'flex-start'};
`

export default TableCell
