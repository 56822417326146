import React, { createContext, useContext, useState, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { formatTokenAmount } from '../utils/math-utils'
import moment from 'moment'
import SubgraphsContext from './SubgraphsContext'

const EnvironmentContext = createContext()

export const TaskConfigProvider = ({ id, children }) => {
  const subgraphs = useContext(SubgraphsContext)
  const [taskConfigData, setTaskConfigData] = useState({
    data: null,
    isLoading: true,
    isError: false,
    refetch: () => {}
  })

  const queryClient = useQueryClient()

  const refetch = () => {
    queryClient.invalidateQueries(['useTaskConfig', id])
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTaskConfigData({ ...taskConfigData, isLoading: true, isError: false })
        const data = await fetchAllTaskConfig(id, subgraphs)
        setTaskConfigData({ data, isLoading: false, isError: false, refetch })
      } catch (error) {
        setTaskConfigData({ ...taskConfigData, isLoading: false, isError: true })
      }
    }

    fetchData()
  }, [id, subgraphs])

  return (
    <EnvironmentContext.Provider value={taskConfigData}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export default EnvironmentContext

const fetchTaskConfig = async (chainId, id, subgraphUrl) => {
  let response = await request(
    subgraphUrl,
    gql`
      query TasksQuery {
        environment(id: ${'"' + id.toLowerCase() + '"'}) {
          namespace
          id
          tasks {
            id
            name
            permissions
            taskConfig {
              connector
              acceptanceList {
                id
                tokens
                type
              }
              customMaxSlippages {
                id
                maxSlippage
                token {
                  decimals
                  id
                  name
                  symbol
                }
              }
              customDestinationChains {
                destinationChain
                token {
                  decimals
                  id
                  name
                  symbol
                }
              }
              customMaxBridgeFees {
                token {
                  decimals
                  id
                  name
                  symbol
                }
                maxBridgeFee {
                  amount
                  id
                  token {
                    decimals
                    id
                    name
                    symbol
                  }
                }
              }
              customTokenOuts {
               tokenOut {
                 decimals
                 id
                 name
                 symbol
               }
               token {
                 decimals
                 id
                 name
                 symbol
               }
             }
              customTokenThresholds {
                threshold {
                  id
                  max
                  min
                  token {
                    decimals
                    id
                    name
                    symbol
                  }
                }
              }
              customVolumeLimits {
                token {
                  decimals
                  id
                  name
                  symbol
                }
                volumeLimit {
                  amount
                  id
                  period
                  token {
                    decimals
                    id
                    name
                    symbol
                  }
                }
              }
              defaultTokenThreshold {
               max
               min
               token {
                 decimals
                 id
                 name
                 symbol
               }
             }
              defaultVolumeLimit {
                 amount
                 id
                 period
              }
              defaultTokenOut {
                decimals
                id
                name
                symbol
              }
              defaultDestinationChain
              defaultMaxSlippage
              defaultMaxBridgeFee {
               amount
               token {
                 decimals
                 id
                 name
                 symbol
               }
              }
              gasLimits {
                gasPriceLimit
                priorityFeeLimit
                txCostLimit
                txCostLimitPct
              }
              nextBalanceConnector
              previousBalanceConnector
              recipient
              timeLock {
                window
                mode
                allowedAt
                frequency
              }
            }
          }
        }
      }
    `
  )
  if (response && response.environment) {
    if (response.environment.tasks) {
      const modifiedTasks = response.environment.tasks.map(task => {
        if (task.taskConfig) {
          const taskItem = task.taskConfig
          const transformedResponse = {
            address: [{
              network: parseInt(chainId),
              address: task.id,
            }, ],
            recipient: [{
              network: parseInt(chainId),
              recipient: taskItem.recipient,
            }, ],
            acceptanceList: [{
              network: parseInt(chainId),
              token: taskItem.acceptanceList.tokens[0],
            }, ],
            limit: [],
            connector: [{
              network: parseInt(chainId),
              previousBalanceConnector: taskItem.previousBalanceConnector,
              nextBalanceConnector: taskItem.nextBalanceConnector,
            }, ],
            protocolConnector: [{
              network: parseInt(chainId),
              connector: taskItem.connector,
            }, ],
            timeLock: [],
            defaultConfig: [],
            custom: [],
          }

          if (taskItem.gasLimits) {
            const costLimit = taskItem.gasLimits.txCostLimitPct

            transformedResponse.limit.push({
              network: parseInt(chainId),
              txCostLimit: taskItem.gasLimits.txCostLimit,
              txCostLimitPct: (formatTokenAmount(costLimit, 18, {
                digits: 2
              }) * 100) + ' %',
              priorityFeeLimit: taskItem.gasLimits.priorityFeeLimit,
              gasPriceLimit: formatTokenAmount(taskItem.gasLimits.gasPriceLimit, 9, {
                digits: 2
              }) + ' Gwei',
            })
          }

          if (taskItem.timeLock) {
            const windowValue = taskItem.timeLock.window
            const frecuency = taskItem.timeLock.frequency
            const allowedAt = taskItem.timeLock.allowedAt
            const mode = taskItem.timeLock.mode

            transformedResponse.timeLock.push({
              network: parseInt(chainId),
              window: windowValue + ' (' + moment.duration(windowValue, 'seconds').asDays() + ' days)',
              mode: mode,
              allowedAt: allowedAt + ' (' + moment.unix(allowedAt).utc().format('YYYY-MM-DD h:mm A [GMT]') + ')',
              frequency: mode === 'seconds' || mode === 'Seconds' ? frecuency + ' (' + moment.duration(frecuency, 'seconds').asDays() + ' days)' : frecuency + (frecuency === '1' ? ' month' : ' months'),
            })
          }

          let defaultConfigItem = {
            network: parseInt(chainId),
          }

          if (taskItem.defaultMaxBridgeFee) {
            defaultConfigItem.maxFee = formatTokenAmount(taskItem.defaultMaxBridgeFee.amount, taskItem.defaultMaxBridgeFee.token.decimals, { digits: 2 }) + ' ' + taskItem.defaultMaxBridgeFee.token.symbol
          }

          if (taskItem.defaultMaxSlippage) {
            defaultConfigItem.maxSlippage = formatTokenAmount(taskItem.defaultMaxSlippage, 18, { digits: 2 }) * 100 + ' %'
          }

          if (taskItem.defaultTokenThreshold) {
            defaultConfigItem.minThreshold = {
              amount: taskItem.defaultTokenThreshold?.min,
              token: { ...taskItem.defaultTokenThreshold?.token, chainId: parseInt(chainId) }
            }
            defaultConfigItem.maxThreshold = {
              amount: taskItem.defaultTokenThreshold?.max,
              token: { ...taskItem.defaultTokenThreshold?.token, chainId: parseInt(chainId) }
            }
          }

          if (taskItem.defaultTokenOut) {
            defaultConfigItem.tokenOut = { ...taskItem.defaultTokenOut, chainId: chainId }
          }

          if (taskItem.defaultVolumeLimit && taskItem.defaultVolumeLimit.period !== 0 && taskItem.defaultVolumeLimit.period !== '0') {
            defaultConfigItem.volumeLimit = taskItem.defaultVolumeLimit
          }

          if (taskItem.defaultDestinationChain) {
            defaultConfigItem.destinationChain = taskItem.defaultDestinationChain
          }

          transformedResponse.defaultConfig.push(defaultConfigItem)

          const customConfigItem = {}

          const mergeArrays = (arr, type) => {
            for (const item of arr) {
              let value = ''
              if (type === 'maxSlippage') {
                value = formatTokenAmount(item.maxSlippage, 18, { digits: 2 }) * 100 + ' %'
              }
              if (type === 'destinationChains') {
                value = item.destinationChain
              }
              if (type === 'maxFee') {
                value = formatTokenAmount(item.maxBridgeFee.amount, item.maxBridgeFee.token.decimals, { digits: 2 }) + ' ' + item.maxBridgeFee.token.symbol
              }
              if (type === 'tokenOut') {
                value = { ...item.tokenOut, chainId: parseInt(chainId) }
              }
              if (type === 'tokenThreshold') {
                value = {
                  minThreshold: {
                    amount: item.threshold?.min,
                    token: { ...item.threshold?.token, chainId: parseInt(chainId) },
                  },
                  maxThreshold: {
                    amount: item.threshold?.max,
                    token: { ...item.threshold?.token, chainId: parseInt(chainId) },
                  }
                }
              }
              if (type === 'volumeLimit') {
                value = { ...item.volumeLimit, token: { ...item.volumeLimit.token, chainId: parseInt(chainId) } }
              }

              const symbol = item?.token?.symbol
              if (!customConfigItem[symbol]) {
                customConfigItem[symbol] = { network: parseInt(chainId), [type]: value }
              } else {
                customConfigItem[symbol][type] = value
              }
            }
          }

          if (taskItem.customMaxSlippages && taskItem.customMaxSlippages.length > 0) {
            mergeArrays(taskItem.customMaxSlippages, 'maxSlippage')
          }
          if (taskItem.customDestinationChains && taskItem.customDestinationChains.length > 0) {
            mergeArrays(taskItem.customDestinationChains, 'destinationChains')
          }
          if (taskItem.customMaxBridgeFees && taskItem.customMaxBridgeFees.length > 0) {
            mergeArrays(taskItem.customMaxBridgeFees, 'maxFee')
          }
          if (taskItem.customTokenOuts && taskItem.customTokenOuts.length > 0) {
            mergeArrays(taskItem.customTokenOuts, 'tokenOut')
          }
          if (taskItem.customTokenThresholds && taskItem.customTokenThresholds.length > 0) {
            mergeArrays(taskItem.customTokenThresholds, 'tokenThreshold')
          }
          if (taskItem.customVolumeLimits && taskItem.customVolumeLimits.length > 0) {
            mergeArrays(taskItem.customVolumeLimits, 'volumeLimit')
          }

          if (Object.keys(customConfigItem).length !== 0) {
            transformedResponse.custom.push(customConfigItem)
          }

          return { id: task.id, name: task.name, permissions: parseInt(task.permissions), taskConfig: transformedResponse }
        } else {
          return { id: task.id, name: task.name, permissions: parseInt(task.permissions), taskConfig: null }
        }
      })
      return { ...response.environment, tasks: modifiedTasks }
    } else {
      return { ...response.environment, tasks: null }
    }
  } else {
    return null
  }
}

const fetchAllTaskConfig = async (id, subgraphs) => {
  const environment = {}
  await Promise.all(Object.keys(subgraphs).map(async (chainId) => {
    const response = await fetchTaskConfig(chainId, id, subgraphs[chainId])
    if (response !== null) {
      environment.namespace = response.namespace
      environment.id = response.id
      if (response.tasks?.length > 0) {
        if (!environment.tasks || environment.tasks.length === 0) {
          environment.tasks = []
          // eslint-disable-next-line array-callback-return
          response.tasks.map(task => {
            let allConfigs = {
              Address: [],
              'Acceptance List': [],
              'Gas Limits': [],
              'Balance Connector': [],
              'Protocol Connector': [],
              'Time Lock': [],
              Custom: [],
              Recipient: [],
              'Default Config': [],
            }
            const configs = task.taskConfig
            if (configs) {
              allConfigs['Address'].push(...configs.address)
              allConfigs['Acceptance List'].push(...configs.acceptanceList)
              allConfigs['Default Config'].push(...configs.defaultConfig)
              allConfigs['Gas Limits'].push(...configs.limit)
              allConfigs['Balance Connector'].push(...configs.connector)
              allConfigs['Protocol Connector'].push(...configs.protocolConnector)
              allConfigs['Time Lock'].push(...configs.timeLock)
              allConfigs['Custom'].push(...configs.custom)

              if (configs.recipient[0].recipient) {
                allConfigs['Recipient'].push(...configs.recipient)
              }

              environment.tasks.push({
                id: task.id,
                name: task.name,
                permissions: parseInt(task.permissions),
                taskConfig: allConfigs,
                chainPermissions: [{chainId: chainId, permissions: task.permissions}]
              })
            }
          })
        } else {
          response.tasks.forEach(task => {
            const existingTask = environment.tasks.find(item => item.id === task.id)

            const allConfigs = existingTask ? existingTask.taskConfig : {
              Address: [],
              'Acceptance List': [],
              'Gas Limits': [],
              'Balance Connector': [],
              'Protocol Connector': [],
              'Time Lock': [],
              Custom: [],
              Recipient: [],
              'Default Config': [],
            }
            const configs = task.taskConfig

            if (configs && allConfigs) {
              allConfigs['Address'].push(...configs.address)
              allConfigs['Acceptance List'].push(...configs.acceptanceList)
              allConfigs['Default Config'].push(...configs.defaultConfig)
              allConfigs['Gas Limits'].push(...configs.limit)
              allConfigs['Balance Connector'].push(...configs.connector)
              allConfigs['Protocol Connector'].push(...configs.protocolConnector)
              allConfigs['Time Lock'].push(...configs.timeLock)
              allConfigs['Custom'].push(...configs.custom)

              if (configs.recipient[0].recipient) {
                allConfigs['Recipient'].push(...configs.recipient)
              }
            }

            if (existingTask && allConfigs) {
              existingTask.taskConfig = allConfigs
              existingTask.permissions += parseInt(task.permissions)
              existingTask.chainPermissions.push({chainId: chainId, permissions: parseInt(task.permissions)})
            } else {
              environment.tasks.push({
                id: task.id,
                name: task.name,
                permissions: task.permissions,
                taskConfig: configs ? allConfigs : null,
                chainPermissions: [{chainId: chainId, permissions: parseInt(task.permissions)}]
              })
            }
          })

        }
      }
    }
  }))

  console.log(environment)
  return environment
}
