import React from 'react'
import styled from 'styled-components'
import Navbar from '../structure/Navbar'
import { Heading2, Container } from '../styles/texts'


const List = ({ width }) => {

  return (
    <ListSection>
      <Navbar />
      <TitleContainer>
        <Heading2>The environments you are looking for, does not exist </Heading2>
      </TitleContainer>
    </ListSection>
  )
}

const ListSection = styled.section`
  height: auto;
  padding: 0 0 80px 0;
  color: ${props => props.theme.textWhite};
  width: 100%;
  margin: auto;
`

const TitleContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0;
`

export default List
