import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Breadcrumbs from '../structure/Breadcrumbs'
import ReportsChart from '../sections/ReportsChart'
import { Skeleton } from '../styles/general'
import { Heading1, Heading2, SmallText, Label, Container } from '../styles/texts'
import useReports from '../hooks/useReports'
import useSlippage from '../hooks/useSlippage'
import { useExecutionsByDates } from '../hooks/useExecutions'
import background from '../assets/report-form.svg'
import calendar from '../assets/calendar.svg'
import browse from '../assets/browse.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

const Reports = ({ width }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [component, setComponent] = useState(false)

  const handleStartDateChange = (date) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('startDate')
    searchParams.delete('endDate')
    const newURL = `${window.location.pathname}`
    window.history.replaceState(null, '', newURL)
    setStartDate(date)
    setComponent(false)
  }

  const handleEndDateChange = (date) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('startDate')
    searchParams.delete('endDate')
    const newURL = `${window.location.pathname}`
    window.history.replaceState(null, '', newURL)
    setEndDate(date)
    setComponent(false)
  }

  const generateReport = () => {
    if (startDate && endDate) {
      const searchParams = new URLSearchParams()
      searchParams.set('startDate', startDate.toISOString())
      searchParams.set('endDate', endDate.toISOString())
      navigate(`${location.pathname}?${searchParams.toString()}`)
      setComponent(true)
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const startDateParam = searchParams.get('startDate')
    const endDateParam = searchParams.get('endDate')

    if (startDateParam && endDateParam) {
      setStartDate(new Date(startDateParam))
      setEndDate(new Date(endDateParam))
      setComponent(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <ReportsSection>
      <Container>
        <Breadcrumbs />
        <Heading1>Performance Report</Heading1>
        <br />
        <Form background={background}>
          <Calendar label="From date" selectedDate={startDate} onChange={handleStartDateChange} />
          <Calendar label="To date" selectedDate={endDate} onChange={handleEndDateChange} />
          <Button className="violet" onClick={generateReport}>Generate Report</Button>
        </Form>
        <br />
        <br />
        <br />
      </Container>
      { component ?
        <>
          <Separator/>
          <Container>
            <Report startDate={startDate} endDate={endDate} chainId={1}/>
          </Container>
        </>
        :
        <Container>
          <EmptyState />
        </Container>
      }
        <br />
        <br />

    </ReportsSection>
  )
}

const Calendar = ({ label, selectedDate, onChange }) => {
  const customDateButtonRef = useRef(null)
  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        customInput={<CustomDateButton ref={customDateButtonRef} label={label} />}
      />
    </div>
  )
}

const CustomDateButton = React.forwardRef(({ value, onClick, label }, ref) => {
  return (
    <Button ref={ref} onClick={onClick}>
      <>{value ? moment(value).format('YYYY-MM-DD') : label} <img src={calendar} alt="calendar"/></>
    </Button>
  )
})

const EmptyState = () => {
  return (
    <EmptyStateContainer>
      <div>
        <img src={browse} alt="browse"/>
        <Heading2>Start browsing</Heading2>
        <SmallText>Generate a report for previous periods to gain a detailed overview of task completion and transactions.</SmallText>
      </div>
    </EmptyStateContainer>
  )
}

const Report = ({startDate, endDate, chainId}) => {
  const params = useParams()
  const slippage = useSlippage(params.id, chainId, new Date(startDate), new Date(endDate))
  const executions = useExecutionsByDates(params.id, chainId, new Date(startDate), new Date(endDate))

  let totals = {
    executionSucceeded: 0,
    executionDelayed: 0,
    executionReverted: 0,
    executionNotReached: 0,
    notSimulated: 0,
    simulationFailed: 0,
    simulationReverted: 0,
    simulationSucceeded: 0,
    transactionReverted: 0
  }

  if(executions.data) {
    for (let date in executions.data) {
      if (Object.prototype.hasOwnProperty.call(executions.data, date)) {
        let item = executions.data[date]

        for (let attribute in item) {
          if (Object.prototype.hasOwnProperty.call(item, attribute)) {
            totals[attribute] += item[attribute]
          }
        }
      }
    }
  }

  return (
      <div>
        <Heading2>{moment(new Date(startDate)).format('MMMM Do')} - {moment(new Date(endDate)).format('MMMM Do')} results</Heading2>
        {
          executions.isLoading || slippage.isLoading ?
          <>
            <Skeleton height="24px" width="100%" /></>
          :
            <>
              <Status>
                  <div>
                    <Label>Total Value Swapped</Label>
                    <Heading2>$ {slippage?.data?.totalSwapped?.toFixed(2)}</Heading2>
                  </div>
                  <div>
                    <Label>Tasks Completed</Label>
                    <Heading2>{totals.simulationSucceeded + totals.executionSucceeded}</Heading2>
                    <SmallText>Out of {totals.notSimulated + totals.simulationFailed +
                      totals.simulationSucceeded + totals.simulationReverted
                    } simulations </SmallText>
                  </div>
                  <div>
                    <Label>Success Rate</Label>
                    <Heading2>{totals.executionSucceeded  ?
                      ((totals.executionSucceeded/((totals.executionReverted || 0)
                      + totals.executionSucceeded ))* 100).toFixed(1) + ' %': 'no data'}
                    </Heading2>
                    <SmallText>
                      {((totals.notSimulated + totals.simulationFailed +
                        totals.simulationSucceeded + totals.simulationReverted)/
                        (totals.simulationSucceeded + totals.executionSucceeded)).toFixed(2)
                      }
                      {' '}simulations per task
                    </SmallText>
                  </div>
                  <div>
                    <Label>Average Slippage</Label>
                    <Heading2>
                      {slippage.data && slippage.data.slippageCount && slippage.data?.slippageCount !== 0 ?
                      (slippage.data.totalSlippage / slippage.data.slippageCount).toFixed(2) : 0 } %
                    </Heading2>
                  </div>
                </Status>

              <Chart startDate={startDate} endDate={endDate} chainId={chainId} />
            </>
        }
      </div>
  )
}


const Chart = ({startDate, endDate, chainId}) => {
  const params = useParams()
  const { data, loading } = useReports(params.id, chainId, new Date(startDate), new Date(endDate))

  return (
      <>
        {!loading && data && (
           <div>
            <br/><br/><br/><br/>
             <Heading2>Task overview</Heading2>
             <ChartContainer><ReportsChart data={data} envId={params.id}/></ChartContainer>
           </div>
         )}
      </>
    )
}

const Status = styled.div`
  border-radius: 24px;
  background: #A89AFF1A;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 100px;
  h1, p {
    margin: 0;
  }
`

const ChartContainer = styled.div`
  margin-top: 50px;
  border-radius: 24px;
  padding: 20px;
  background: #A89AFF1A;

`

const ReportsSection = styled.section`
  height: auto;
  padding: 20px 0;
  color: ${props => props.theme.textWhite};
  width: 100%;
  margin: auto;
`

const Form = styled.div`
  background: url(${props => props.background}) no-repeat center;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  width: 100%;
  border-radius: 24px;
  box-sizing: border-box;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9075;
    background: var(--shadow, rgba(36, 39, 49, 0.70));
    mix-blend-mode: multiply;
    filter: blur(43.49250793457031px);
    border-radius: 24px; /* Ajusta según el borde del formulario */
    z-index: -1;
  }
`

const Button = styled.button`
  border-radius: 10px;
  background: rgba(168, 154, 255, 0.10);
  border: solid 0px;
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'DMSansBold';
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  padding: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 33px;
  &.violet {
    background: #6F5CE6;
    &:hover {
      background: #463888;
    }
  }
`

const EmptyStateContainer = styled.div`
  text-align: center;
  height: 400px;
  width: 100%;
  border-radius: 24px;
  border: 1px solid #A89AFF;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #A7ACC1;
    max-width: 500px;
  }
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: #373E4B;
  margin-bottom: 70px;
`



export default Reports
