import React, { useContext } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext from '../context/SubgraphsContext'

const usePermisions = id => {
  const subgraphs = useContext(SubgraphsContext)
  const queryClient = useQueryClient()

  const { data, isLoading, isError } = useQuery(
    ['usePermisions', id],
    () => fetchAllPermissions(id.toString(), subgraphs),
    {
      enabled: !!subgraphs && Object.keys(subgraphs).length > 0,
    }
  )

  const refetch = () => {
    queryClient.invalidateQueries(['usePermisions', id])
  }

  return {
    data,
    isLoading,
    isError,
    refetch,
  }
}

const fetchPermissions = async (chainId, id, url) => {
  let permision = await request(
    url,
    gql`
    query Permissions {
      permissions(where: {where: ${'"' + id.toLowerCase() + '"'}}) {
        id
        who
        what
        method
      }
    }
    `
  )
  return permision?.permissions.map(item => {
    return { ...item, chainId: chainId }
  }) || ''
}

const fetchAllPermissions = async (id, subgraphs) => {
  const allPermissions = []
  await Promise.all(Object.keys(subgraphs).map(async (chainId) => {
    const permissions = await fetchPermissions(chainId, id, subgraphs[chainId])
    allPermissions.push(...permissions)
  }))

  return allPermissions
}

export default usePermisions
