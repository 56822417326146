import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import Overview from '../assets/menuIcons/Overview.js'
import Activity from '../assets/menuIcons/Activity.js'
import Settings from '../assets/menuIcons/Settings.js'
import Reports from '../assets/menuIcons/Reports.js'
import smartVault from '../assets/environments/Placeholder2.svg'
import background from '../assets/background-sidebar.svg'
import { EnvironmentsDictionary } from '../constants/environmentsDictionary'

const Sidebar = () => {
  const params = useParams()
  const id = params.id

  const url = window.location.href
  let page = url.split(id)[1]
  if (page?.startsWith('/')) {
    page = page.slice(1)
  }

  const active = page

  return (
    <SidebarSection background={background}>
      <Menu>
        <Title>
          <img
            alt="smart vault logo"
            src={EnvironmentsDictionary[id]?.logo || smartVault}
          />
        </Title>
        <Separator />
        <Item to={'/' + id + '/overview'}>
          <Overview fill={active !== 'overview' ? '#808080' : '#6F5CE6'} />
        </Item>
        <Item to={'/' + id + '/activity'}>
          <Activity fill={active !== 'activity' ? '#808080' : '#6F5CE6'} />
        </Item>
        <Item to={'/' + id + '/reports'} style={{left: '20px', top: '5px'}}>
          <Reports fill={active !== 'reports' ? '#808080' : '#6F5CE6'} />
        </Item>
        <Item to={'/' + id + '/settings'}>
          <Settings fill={active !== 'settings' ? '#808080' : '#6F5CE6'} />
        </Item>
      </Menu>
    </SidebarSection>
  )
}

const Item = styled(Link)`
  height: 37px;
  padding: 10px 0px;
  margin: 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 25px;
  cursor: pointer;
`

const Title = styled.div`
  margin: 7px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  left: 20px;
  cursor: pointer;
  height: 100px;
  padding: 20px 0px;
  width: 100%;
  img {
    height: 35px;
  }
`

const Separator = styled.div`
  height: 1px;
  border-bottom: 1px solid #4e4b66;
  width: 100%;
`

const Menu = styled.div`
  height: 100%;
  display: flex;
  position: sticky;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background: rgba(168, 154, 255, 0.12);
`

const SidebarSection = styled.section`
  background: url(${props => props.background}) no-repeat top center;
  background-size: cover;
  z-index: 100;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  width: 75px;
  flex-shrink: 1;
  border-right: 1.5px solid #2c3041;
  transition: all 0.3s ease-in-out;
`

export default Sidebar
