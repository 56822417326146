import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Networks from '../components/Networks'
import defaultImg from '../assets/environments/Placeholder2.svg'
import { EnvironmentsDictionary } from '../constants/environmentsDictionary'
import useRelayedExecutions from '../hooks/useRelayedExecutions'
import { parseActionName } from '../utils/general-utils'
import { v2url } from '../constants/v2url'

const EnvironmentCard = ({ environment, v2 = false, onReady }) => {
  return (
    <>
      {v2 ? (
        <a
          href={v2url + '/smart-vaults/' + environment.mimicId}
          target="_blank"
          rel="noreferrer"
        >
          <CardSection environment={environment} v2={v2} onReady={onReady} />
        </a>
      ) : (
        <Link key={environment.mimicId} to={'/' + environment.mimicId + '/overview'}>
          <CardSection environment={environment} v2={v2} onReady={onReady} />
        </Link>
      )}
    </>
  )
}

const CardSection = ({ environment, v2 = false, onReady }) => {
  const { data, isLoading } = useRelayedExecutions(environment.mimicId, 7)
  useEffect(
    () => {
      if (environment?.chainIds || !isLoading) {
        onReady()
      }
    },
    [environment, onReady, isLoading]
  )
  if (!environment?.chainIds) return <></>

  return (
    <Card>
      <div>
        <img
          alt="smartvault"
          src={
            v2
              ? environment.logo
              : EnvironmentsDictionary[environment.mimicId]?.logo || defaultImg
          }
        />
      </div>
      <Title>
        {EnvironmentsDictionary[environment.mimicId]?.name ||
          (environment?.namespace
            ? parseActionName(environment?.namespace)
            : '') ||
          environment.name}
        <Chip v={v2 ? 'v2' : 'v3'}>{v2 ? 'v2' : 'v3'}</Chip>
      </Title>

      <div className="flex">
        <Networks networks={environment?.chainIds} />
        <p className="flex">
          {environment?.chainIds?.length}{' '}
          {environment?.chainIds?.length === 1 ? 'network' : 'networks'}
        </p>
      </div>
      <br />
      {!v2 ? (
        <p style={{ maxwidth: '181px', height: '32px'}}>
          {!isLoading && data?.length
            ? data?.length + ' tasks executed'
            : 'No new task'}
        </p>
      ) : (
        <div className="task" />
      )}
      <p className="bold">View</p>
    </Card>
  )
}

const Title = styled.h3`
  color: #fcfcfd;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'VisbyBold';
  font-size: 26px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 1px;
  margin-bottom: 10px;
  margin-top: 80px;
`

const Card = styled.section`
  background: #242734;
  box-shadow: 0px 4px 40px rgba(26, 28, 30, 0.24);
  border-radius: 20px;
  padding: 24px;
  width: 246px;
  height: 396px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .flex {
    display: flex;
    align-items: center;
  }
  .task {
    width: 100%;
    height: 32px;
  }
  img {
    height: 55px;
    border-radius: 100px;
    margin-bottom: 24px;
  }
  p {
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'DMSans';
    font-size: 17px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.75px;
    text-align: left;
    margin: 0;
    &.bold {
      color: #6f5ce6;
      font-family: 'DMSansBold';
      font-weight: 700;
    }
  }
`

const Chip = styled.div`
  color: white;
  background-color: ${props => (props.v === 'v3' ? '#6F5CE6' : '#9b9797')};
  font-size: 13px;
  max-width: 20px;
  line-height: 10px;
  border-radius: 100px;
  padding: 5px 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

export default EnvironmentCard
