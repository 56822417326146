import styled from 'styled-components'
import { StandardText } from '../styles/texts'
import tokenSample from '../assets/token-sample.svg'
import { tokens } from '../constants/tokens'
import openLink from '../assets/open.svg'
import { getEtherscanLink } from '../utils/web3-utils'

const Token = ({ address, width = 1000, open = false, item={} }) => {
  const token = tokens.filter(
    obj => obj.address?.toLowerCase() === address?.toLowerCase()
  )
  const medium = 800

  if (token && token[0]) {
    return (
      <>
        <TokenName>
          <img
            src={token[0]?.logoURI}
            alt="Logo"
            onError={e => {
              e.target.onerror = null
              e.target.src = tokenSample
            }}
          />
          {width >= medium && token[0]?.name + ','} <span>{token[0]?.symbol}</span>
          {open && <a
            href={getEtherscanLink(item.chainId || token[0]?.chainId, address, 'address')}
            target="_blank"
            rel="noreferrer"
          >
            <ImgSm src={openLink} />
          </a>}
        </TokenName>
      </>
    )
  }

  if(item && item.symbol && item.chainId) {
    return (
      <TokenName>
        {item.name + ','}<span>{item.symbol}</span>
        {open && <a
          href={getEtherscanLink(item.chainId, item.id, 'address')}
          target="_blank"
          rel="noreferrer"
        >
          <ImgSm src={openLink} />
        </a>}
      </TokenName>
  )}



  return <>{address}</>
}

const TokenName = styled(StandardText)`
  display: flex;
  align-items: center;
  margin: 0;
  span {
    padding-left: 5px;
    color: ${props => props.theme.mainLight};
  }
  img {
    height: 30px;
    padding-right: 7px;
  }
`

const ImgSm = styled.img`
  height: 15px !important;
  width: 15px !important;
  padding: 0px !important;
  margin: 0px 5px;
  margin-top: 1px;
`

export default Token
